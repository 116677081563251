window.RuiDatepicker = (function () {
  "classList" in document.documentElement ||
    Object.defineProperty(HTMLElement.prototype, "classList", {
      get: function () {
        function e(e) {
          return function (a) {
            var r = t.className.split(/\s+/g),
              n = r.indexOf(a);
            e(r, n, a), (t.className = r.join(" "));
          };
        }
        var t = this;
        return {
          add: e(function (e, t, a) {
            ~t || e.push(a);
          }),
          remove: e(function (e, t) {
            ~t && e.splice(t, 1);
          }),
          toggle: e(function (e, t, a) {
            ~t ? e.splice(t, 1) : e.push(a);
          }),
          contains: function (e) {
            return !!~t.className.split(/\s+/g).indexOf(e);
          },
          item: function (e) {
            return t.className.split(/\s+/g)[e] || null;
          },
        };
      },
    });
  var datePicker = function () {
    this.gearDate,
      (this.minY = 1920),
      (this.minM = 1),
      (this.minD = 1),
      (this.maxY = 2030),
      (this.maxM = 12),
      (this.maxD = 31),
      (this.type = 1);
  };
  return (
    (datePicker.prototype = {
      init: function init(options) {
        "string" == typeof options && (options = { id: options }),
          (this.trigger = document.querySelector(options.id)),
          this.bindEvent("date"),
          (this.callback =
            eval(this.trigger.getAttribute("data-callback")) ||
            options.callback ||
            function () {});
      },
      bindEvent: function (e) {
        function t(e) {
          document.activeElement.blur(),
            (b.gearDate = document.createElement("div")),
            (b.gearDate.className = "gear-date160");
          var t = b.trigger.getAttribute("data-toid-hour"),
            n = b.trigger.getAttribute("data-toid-minute"),
            i = '<div class="date-ctrl date-ctrl--anim">';
          (i += '<div class="date-header J_dateHeader"></div>'),
            (i += '<div class="date-choice J_dateChoice">'),
            (i += '<div class="date-class">'),
            (i += '<div class="date-class__box J_classGongli">公历</div>'),
            (i += '<div class="date-class__box J_classNongli">农历</div>'),
            (i += "</div>"),
            (i += '<div class="date-roll-mask">'),
            (i += t
              ? n
                ? '<div class="date-roll date-roll--hhii">'
                : '<div class="date-roll date-roll--hh">'
              : '<div class="date-roll">'),
            (i +=
              '<div><div class="gear date_yy" data-datetype="date_yy"></div><div class="date-grid"></div></div>'),
            (i +=
              '<div><div class="gear date_mm" data-datetype="date_mm"></div><div class="date-grid"></div></div>'),
            (i +=
              '<div><div class="gear date_dd" data-datetype="date_dd"></div><div class="date-grid"></div></div>'),
            t &&
              ((i +=
                '<div><div class="gear date_hh" data-datetype="date_hh"></div><div class="date-grid"></div></div>'),
              n &&
                (i +=
                  '<div><div class="gear date_ii" data-datetype="date_ii"></div><div class="date-grid"></div></div>')),
            (i += "</div></div>"),
            (i += '<div class="date-btn">'),
            (i += '<div class="date_btn__elem J_finish">确定日期</div>'),
            (i += '<div class="date_btn__elem J_cancel">取消选择</div>'),
            (i += "</div>"),
            (i += "</div>"),
            (i += '<div class="date-confirm J_confirmContainer">'),
            (i += '<div class="date-confirm__tit">确认时间</div>'),
            (i +=
              '<div class="date-confirm__tip">请确认输入的时间是否正确</div>'),
            (i += '<div class="date-confirm__info J_confirmInfo">'),
            (i +=
              '<div class="date-confirm__p">公(阳)历：<b class="J_confirmGongliText"></b></div>'),
            (i +=
              '<div class="date-confirm__p">农(阴)历：<b class="J_confirmNongliText"></b></div>'),
            (i += "</div>"),
            (i += '<div class="date-confirm__btn">'),
            (i +=
              '<div class="date-confirm__cancel J_confirmCancel">返回修改</div>'),
            (i +=
              '<div class="date-confirm__finish J_confirmFinish">确认正确</div>'),
            (i += "</div>"),
            (i += "</div>"),
            (i += "</div>"),
            (b.gearDate.innerHTML = i),
            document.body.appendChild(b.gearDate),
            r();
          var s = "ontouchstart" in window;
          b.gearDate
            .querySelector(".J_cancel")
            .addEventListener(s ? "touchstart" : "click", f),
            b.gearDate
              .querySelector(".J_finish")
              .addEventListener(s ? "touchstart" : "click", h),
            b.gearDate
              .querySelector(".J_confirmCancel")
              .addEventListener(s ? "touchstart" : "click", f),
            b.gearDate
              .querySelector(".J_confirmFinish")
              .addEventListener(s ? "touchstart" : "click", h);
          var d = b.gearDate.querySelector(".J_classGongli"),
            o = b.gearDate.querySelector(".J_classNongli");
          d.addEventListener(
            s ? "touchstart" : "click",
            function () {
              a("gongli");
            },
            !1
          ),
            o.addEventListener(
              s ? "touchstart" : "click",
              function () {
                a("nongli");
              },
              !1
            );
          var l = b.gearDate.querySelector(".date_yy"),
            _ = b.gearDate.querySelector(".date_mm"),
            g = b.gearDate.querySelector(".date_dd"),
            p = b.gearDate.querySelector(".date_hh"),
            w = b.gearDate.querySelector(".date_ii");
          l.addEventListener("touchstart", u),
            _.addEventListener("touchstart", u),
            g.addEventListener("touchstart", u),
            p && p.addEventListener("touchstart", u),
            w && w.addEventListener("touchstart", u),
            l.addEventListener("mousedown", c),
            _.addEventListener("mousedown", c),
            g.addEventListener("mousedown", c),
            p && p.addEventListener("mousedown", c),
            w && w.addEventListener("mousedown", c),
            l.addEventListener("touchmove", v),
            _.addEventListener("touchmove", v),
            g.addEventListener("touchmove", v),
            p && p.addEventListener("touchmove", v),
            w && w.addEventListener("touchmove", v),
            l.addEventListener("touchend", y),
            _.addEventListener("touchend", y),
            g.addEventListener("touchend", y),
            p && p.addEventListener("touchend", y),
            w && w.addEventListener("touchend", y),
            b.gearDate.addEventListener("touchmove", function (e) {
              e.preventDefault();
            }),
            navigator.userAgent.indexOf("Firefox") > 0
              ? (b.gearDate.addEventListener(
                  "DOMMouseScroll",
                  function (e) {
                    e.preventDefault();
                  },
                  !1
                ),
                l.addEventListener("DOMMouseScroll", m, !1),
                _.addEventListener("DOMMouseScroll", m, !1),
                g.addEventListener("DOMMouseScroll", m, !1),
                p && p.addEventListener("DOMMouseScroll", m, !1),
                w && w.addEventListener("DOMMouseScroll", m, !1))
              : ((b.gearDate.onmousewheel = function (e) {
                  return !1;
                }),
                (l.onmousewheel = m),
                (_.onmousewheel = m),
                (g.onmousewheel = m),
                p && (p.onmousewheel = m),
                w && (w.onmousewheel = m));
        }
        function a(e) {
          var t = b.gearDate.querySelector(".J_classNongli"),
            a = b.gearDate.querySelector(".J_classGongli"),
            r = 0;
          if (
            ("nongli" == e && 1 != b.type
              ? ((t.className =
                  t.className
                    .replace(/active/, "")
                    .replace(/(^\s*)|(\s*$)/g, "") + " active"),
                (a.className = a.className.replace(/active/, "")),
                (b.type = 1),
                (r = 1))
              : "gongli" == e &&
                0 != b.type &&
                ((t.className = t.className.replace(/active/, "")),
                (a.className =
                  a.className
                    .replace(/active/, "")
                    .replace(/(^\s*)|(\s*$)/g, "") + " active"),
                (b.type = 0),
                (r = 1)),
            r)
          ) {
            var i = b.maxY - b.minY + 1,
              s = parseInt(
                Math.round(
                  b.gearDate.querySelector(".date_yy").getAttribute("val")
                )
              ),
              o =
                parseInt(
                  Math.round(
                    b.gearDate.querySelector(".date_mm").getAttribute("val")
                  )
                ) + 1,
              l =
                parseInt(
                  Math.round(
                    b.gearDate.querySelector(".date_dd").getAttribute("val")
                  )
                ) + 1,
              m = (s % i) + b.minY,
              e = b.type ? 0 : 1,
              c = w[s].Intercalation ? w[s].Intercalation : 0;
            !b.type &&
              c &&
              (c == o - 1 ? (o = -(o - 1)) : c < o - 1 ? (o -= 1) : (o = o));
            var u = d(e, m, o, l),
              v = w[u.yy - b.minY].Intercalation
                ? w[u.yy - b.minY].Intercalation
                : 0;
            v &&
              b.type &&
              (u.mm < 0 ? (u.mm = 1 - u.mm) : u.mm > v && (u.mm = u.mm + 1)),
              b.gearDate
                .querySelector(".date_yy")
                .setAttribute("val", u.yy - b.minY),
              b.gearDate
                .querySelector(".date_mm")
                .setAttribute("val", u.mm - 1),
              b.gearDate
                .querySelector(".date_dd")
                .setAttribute("val", u.dd - 1),
              b.gearDate.querySelector(".date_yy").setAttribute("top", ""),
              n();
          }
        }
        function r() {
          var e = new Date(),
            t = { yy: e.getYear(), mm: e.getMonth(), dd: e.getDate() - 1 };
          if (
            /^\d{4}-\d{1,2}-\d{1,2}$/.test(b.trigger.getAttribute("data-date"))
          ) {
            var a = b.trigger.getAttribute("data-date").match(/(^|-)\d{1,4}/g);
            (t.yy = a[0] - b.minY),
              (t.mm = a[1].replace(/-/g, "") - 1),
              (t.dd = a[2].replace(/-/g, "") - 1);
          } else (t.yy = t.yy + 1874 - b.minY), (t.mm = 6), (t.dd = 0);
          if (
            (b.gearDate.querySelector(".date_yy").setAttribute("val", t.yy),
            b.gearDate.querySelector(".date_mm").setAttribute("val", t.mm),
            b.gearDate.querySelector(".date_dd").setAttribute("val", t.dd),
            b.gearDate.querySelector(".date_hh"))
          ) {
            var r = 0;
            b.trigger.getAttribute("data-hour") &&
              b.trigger.getAttribute("data-hour") >= 0 &&
              (r = parseInt(
                Math.round(b.trigger.getAttribute("data-hour")) + 1
              )),
              b.gearDate.querySelector(".date_hh").setAttribute("val", r);
          }
          if (b.gearDate.querySelector(".date_ii")) {
            var i = 0;
            b.trigger.getAttribute("data-minute") &&
              b.trigger.getAttribute("data-minute") >= 0 &&
              (i = parseInt(Math.round(b.trigger.getAttribute("data-minute")))),
              b.gearDate.querySelector(".date_ii").setAttribute("val", i);
          }
          if (parseInt(b.trigger.getAttribute("data-type"))) {
            b.type = 1;
            var s = b.gearDate.querySelector(".J_classNongli");
            s.className =
              s.className.replace(/active/, "").replace(/(^\s*)|(\s*$)/g, "") +
              " active";
            var o = b.maxY - b.minY + 1,
              l = (t.yy % o) + b.minY,
              m = t.mm + 1,
              c = t.dd + 1,
              u = d(0, l, m, c),
              v = w[u.yy - b.minY].Intercalation
                ? w[u.yy - b.minY].Intercalation
                : 0;
            u.mm < 0
              ? (u.mm = 1 - u.mm)
              : v && u.mm - 1 >= v && (u.mm = u.mm + 1),
              b.gearDate
                .querySelector(".date_yy")
                .setAttribute("val", u.yy - b.minY),
              b.gearDate
                .querySelector(".date_mm")
                .setAttribute("val", u.mm - 1),
              b.gearDate
                .querySelector(".date_dd")
                .setAttribute("val", u.dd - 1);
          } else {
            b.type = 0;
            var y = b.gearDate.querySelector(".J_classGongli");
            y.className =
              y.className.replace(/active/, "").replace(/(^\s*)|(\s*$)/g, "") +
              " active";
          }
          n();
        }
        function n() {
          var e = b.maxY - b.minY + 1,
            t = b.gearDate.querySelector(".date_yy"),
            a = "";
          if (t && t.getAttribute("val")) {
            for (
              var r = parseInt(t.getAttribute("val")), n = 0;
              n <= e - 1;
              n++
            )
              a += "<div class='tooth'>" + (b.minY + n) + "</div>";
            t.innerHTML = a;
            var d = Math.floor(parseFloat(t.getAttribute("top")));
            if (isNaN(d))
              (t.style.transform = "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-webkit-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-moz-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-ms-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-o-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                t.setAttribute("top", 8 - 2 * r + "em");
            else {
              d % 2 == 0 ? (d = d) : (d += 1), d > 8 && (d = 8);
              var o = 8 - 2 * (e - 1);
              d < o && (d = o),
                (t.style.transform = "translate(0," + d + "em)"),
                (t.style["-webkit-transform"] = "translate(0," + d + "em)"),
                (t.style["-moz-transform"] = "translate(0," + d + "em)"),
                (t.style["-ms-transform"] = "translate(0," + d + "em)"),
                (t.style["-o-transform"] = "translate(0," + d + "em)"),
                t.setAttribute("top", d + "em"),
                (r = Math.abs(d - 8) / 2),
                t.setAttribute("val", r);
            }
            var l = b.gearDate.querySelector(".date_mm");
            if (l && l.getAttribute("val")) {
              a = "";
              var m = parseInt(l.getAttribute("val")),
                c = w[r].Intercalation ? w[r].Intercalation : 0;
              if (c && b.type) var u = 12;
              else var u = 11;
              var v = 0;
              r == e - 1 && (u = (b.type, b.maxM - 1)),
                0 == r && (v = b.type ? b.minM - 1 : b.minM);
              for (var n = 0; n < u - v + 1; n++) {
                var y = v + n + 1;
                b.type
                  ? (y =
                      c && c == n
                        ? s("rm", y - 1)
                        : c && c < n
                        ? s("mm", y - 1)
                        : s("mm", y))
                  : (y += "月"),
                  (a += "<div class='tooth'>" + y + "</div>");
              }
              (l.innerHTML = a),
                m > u
                  ? ((m = u), l.setAttribute("val", m))
                  : m < v && ((m = u), l.setAttribute("val", m)),
                (l.style.transform =
                  "translate(0," + (8 - 2 * (m - v)) + "em)"),
                (l.style["-webkit-transform"] =
                  "translate(0," + (8 - 2 * (m - v)) + "em)"),
                (l.style["-moz-transform"] =
                  "translate(0," + (8 - 2 * (m - v)) + "em)"),
                (l.style["-ms-transform"] =
                  "translate(0," + (8 - 2 * (m - v)) + "em)"),
                (l.style["-o-transform"] =
                  "translate(0," + (8 - 2 * (m - v)) + "em)"),
                l.setAttribute("top", 8 - 2 * (m - v) + "em");
              var _ = b.gearDate.querySelector(".date_dd");
              if (_ && _.getAttribute("val")) {
                a = "";
                var g = parseInt(_.getAttribute("val")),
                  f = i(r, m),
                  h = f - 1,
                  D = 0;
                r == e - 1 &&
                  12 == m + 1 &&
                  (h = b.type ? b.maxD - 25 : b.maxD - 1),
                  0 == r &&
                    2 == m + 1 &&
                    (D = b.type ? b.minD - 1 : b.minD + 6);
                for (var n = 0; n < h - D + 1; n++) {
                  var y = b.type ? s("dd", D + n + 1) : D + n + 1 + "日";
                  a += "<div class='tooth'>" + y + "</div>";
                }
                (_.innerHTML = a),
                  g > h
                    ? ((g = h), _.setAttribute("val", g))
                    : g < D && ((g = D), _.setAttribute("val", g)),
                  (_.style.transform =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (_.style["-webkit-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (_.style["-moz-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (_.style["-ms-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (_.style["-o-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  _.setAttribute("top", 8 - 2 * (g - D) + "em");
                var A = b.gearDate.querySelector(".date_hh");
                if (A && A.getAttribute("val")) {
                  var M = parseInt(A.getAttribute("val"));
                  console.log(M,'MMM')
                  a = "<div class='tooth'>时辰未知</div>";
                  for (var n = 0; n < 13; n++) {
                    var y = s("hh", n);
                    if (b.gearDate.querySelector(".date_ii"))
                      var y = (n < 10 ? "0" + n : n) + "时";
                    a += "<div class='tooth'>" + y + "</div>";
                  }
                  (A.innerHTML = a),
                    (A.style.transform = "translate(0," + (8-4* M) + "em)"),
                    (A.style["-webkit-transform"] =
                      "translate(0," + (8 - 4 * M) + "em)"),
                    (A.style["-moz-transform"] =
                      "translate(0," + (8 - 4 * M) + "em)"),
                    (A.style["-ms-transform"] =
                      "translate(0," + (8 - 4 * M) + "em)"),
                    (A.style["-o-transform"] =
                      "translate(0," + (8 - 4 * M) + "em)"),
                    A.setAttribute("top", 8 - 4 * M + "em");
                }
                var S = b.gearDate.querySelector(".date_ii");
                if (S && S.getAttribute("val")) {
                  var q = parseInt(S.getAttribute("val"));
                  a = "";
                  for (var n = 0; n < 60; n++) {
                    var y = (n < 10 ? "0" + n : n) + "分";
                    a += "<div class='tooth'>" + y + "</div>";
                  }
                  (S.innerHTML = a),
                    (S.style.transform = "translate(0," + (8 - 2 * q) + "em)"),
                    (S.style["-webkit-transform"] =
                      "translate(0," + (8 - 2 * q) + "em)"),
                    (S.style["-moz-transform"] =
                      "translate(0," + (8 - 2 * q) + "em)"),
                    (S.style["-ms-transform"] =
                      "translate(0," + (8 - 2 * q) + "em)"),
                    (S.style["-o-transform"] =
                      "translate(0," + (8 - 2 * q) + "em)"),
                    S.setAttribute("top", 8 - 2 * q + "em");
                }
                p();
              }
            }
          }
        }
        function i(e, t) {
          return 1 == b.type
            ? w[e].MonthDays[t]
              ? 30
              : 29
            : 1 == t
            ? ((e += b.minY),
              (e % 4 == 0 && e % 100 != 0) || (e % 400 == 0 && e % 4e3 != 0)
                ? 29
                : 28)
            : 3 == t || 5 == t || 8 == t || 10 == t
            ? 30
            : 31;
        }
        function s(e, t) {
          var a = [
              "闰正月",
              "闰二月",
              "闰三月",
              "闰四月",
              "闰五月",
              "闰六月",
              "闰七月",
              "闰八月",
              "闰九月",
              "闰十月",
              "闰冬月",
              "闰腊月",
            ],
            r = [
              "正月",
              "二月",
              "三月",
              "四月",
              "五月",
              "六月",
              "七月",
              "八月",
              "九月",
              "十月",
              "十一月",
              "十二月",
            ],
            n = [
              "初一",
              "初二",
              "初三",
              "初四",
              "初五",
              "初六",
              "初七",
              "初八",
              "初九",
              "初十",
              "十一",
              "十二",
              "十三",
              "十四",
              "十五",
              "十六",
              "十七",
              "十八",
              "十九",
              "二十",
              "廿一",
              "廿二",
              "廿三",
              "廿四",
              "廿五",
              "廿六",
              "廿七",
              "廿八",
              "廿九",
              "三十",
              "三十一",
            ],
            i = [
              "00:00-00:59(早子)",
              "01:00-02:59(丑)",
              "03:00-04:59(寅)",
              "05:00-06:59(卯)",
              "07:00-08:59(辰)",
              "09:00-10:59(巳)",
              "11:00-12:59(未)",
              "13:00-14:59(申)",
              "15:00-16:59(酉)",
              "17:00-18:59(酉)",
              "19:00-20:59(戌)",
              "21:00-22:59(亥)",
              "23:00-23:59(子)",
            ],
            s = [
              "子",
              "丑",
              "寅",
              "辰",
              "巳",
              "午",
              "未",
              "申",
              "酉",
              "戌",
              "亥",
              "子",
            ];
          return "rm" == e
            ? a[t - 1]
            : "mm" == e
            ? r[t - 1]
            : "dd" == e
            ? n[t - 1]
            : "hh" == e
            ? i[t]
            : "hhcn" == e
            ? s[t]
            : void 0;
        }
        function d(e, t, a, r) {
          var n = t,
            i = a,
            s = r,
            d = [
              0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365, 396,
              0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366, 397,
            ];
          if (0 == e) {
            var l = parseInt(n),
              m = parseInt(i),
              c = parseInt(s),
              u = m - 1,
              v = o(l),
              y = l - 1940,
              _ = d[14 * v + u] + c,
              g = _ + w[y].BaseKanChih,
              f = g % 60;
            if (
              ((f = f < 22 ? 22 - f : 82 - f),
              (f += 3),
              f < 10 && (f += 60),
              _ <= w[y].BaseDays)
            ) {
              y--;
              var h = l - 1;
              (v = o(h)), (u += 12), (_ = d[14 * v + u] + c);
            } else var h = l;
            var p = w[y].BaseDays;
            for (q = 0; q < 13; q++) {
              var b = p + w[y].MonthDays[q] + 29;
              if (_ <= b) break;
              p = b;
            }
            var D = q + 1,
              A = _ - p,
              M = w[y].Intercalation;
            return (
              0 != M && D > M && --D == M && (D = -M),
              D > 12 && (D -= 12),
              { yy: h, mm: D, dd: A }
            );
          }
          var h = parseInt(n),
            D = parseInt(i),
            A = parseInt(s),
            y = h - 1940,
            M = w[y].Intercalation,
            S = D;
          0 != M && (S > M ? S++ : S == -M && (S = M + 1)), S--;
          for (var _ = 0, q = 0; q < S; q++) _ += w[y].MonthDays[q] + 29;
          _ += w[y].BaseDays + A;
          for (var v = o(h), q = 13; q >= 0 && !(_ > d[14 * v + q]); q--);
          var c = _ - d[14 * v + q];
          if (q <= 11)
            var l = h,
              m = q + 1;
          else
            var l = h + 1,
              m = q - 11;
          return { yy: l, mm: m, dd: c };
        }
        function o(e) {
          return e % 400 == 0 ? 1 : e % 100 == 0 ? 0 : e % 4 == 0 ? 1 : 0;
        }
        function l(e, t, a, r, n, i, s, d, o, l, m, c, u, v, y, _, g) {
          (this.BaseDays = e),
            (this.Intercalation = t),
            (this.BaseWeekday = a),
            (this.BaseKanChih = r),
            (this.MonthDays = [n, i, s, d, o, l, m, c, u, v, y, _, g]);
        }
        function m(e) {
          var e = e || event,
            t = !0;
          t = e.wheelDelta ? e.wheelDelta > 0 : e.detail < 0;
          var a = t ? 21 : -21;
          e.preventDefault();
          for (var r = e.target; ; ) {
            if (r.classList.contains("gear")) break;
            r = r.parentElement;
          }
          clearInterval(r["int_" + r.id]),
            (r["old_" + r.id] = 0),
            (r["o_t_" + r.id] = new Date().getTime());
          var n = r.getAttribute("top");
          (r["o_d_" + r.id] = n ? parseFloat(n.replace(/em/g, "")) : 0),
            (r["new_" + r.id] = a),
            (r["n_t_" + r.id] = new Date().getTime() + 360);
          var i = (18 * (r["new_" + r.id] - r["old_" + r.id])) / 370;
          (r["pos_" + r.id] = r["o_d_" + r.id] + i),
            r.setAttribute("top", r["pos_" + r.id] + "em");
          var s =
            (r["new_" + r.id] - r["old_" + r.id]) /
            (r["n_t_" + r.id] - r["o_t_" + r.id]);
          return (
            Math.abs(s) <= 0.2
              ? (r["spd_" + r.id] = s < 0 ? -0.08 : 0.08)
              : Math.abs(s) <= 0.5
              ? (r["spd_" + r.id] = s < 0 ? -0.16 : 0.16)
              : (r["spd_" + r.id] = s / 2),
            r["pos_" + r.id] || (r["pos_" + r.id] = 0),
            e.preventDefault && e.preventDefault(),
            _(r),
            !1
          );
        }
        function c(e) {
          e.preventDefault();
          for (var t = e.target, a = t, r = !1; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          clearInterval(t["int_" + t.id]),
            (t["old_" + t.id] = e.screenY),
            (t["o_t_" + t.id] = new Date().getTime());
          var n = t.getAttribute("top");
          (t["o_d_" + t.id] = n ? parseFloat(n.replace(/em/g, "")) : 0),
            (document.onmousemove = function (e) {
              (r = !0), (e = e || window.event), e.preventDefault();
              for (var t = a; ; ) {
                if (!t) break;
                if (t.classList.contains("gear")) break;
                t = t.parentElement;
              }
              if (t) {
                (t["new_" + t.id] = e.screenY),
                  (t["n_t_" + t.id] = new Date().getTime());
                var n = (18 * (t["new_" + t.id] - t["old_" + t.id])) / 370;
                (t["pos_" + t.id] = t["o_d_" + t.id] + n),
                  (t.style.transform =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-webkit-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-moz-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-ms-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-o-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  t.setAttribute("top", t["pos_" + t.id] + "em");
              }
            }),
            (document.onmouseup = function (e) {
              if (!r)
                return (
                  (document.onmousemove = null), (document.onmouseup = null), !1
                );
              (e = e || window.event), e.preventDefault();
              for (var t = a; ; ) {
                if (!t) break;
                if (t.classList.contains("gear")) break;
                t = t.parentElement;
              }
              if (t) {
                var n =
                  (t["new_" + t.id] - t["old_" + t.id]) /
                  (t["n_t_" + t.id] - t["o_t_" + t.id]);
                Math.abs(n) <= 0.2
                  ? (t["spd_" + t.id] = n < 0 ? -0.08 : 0.08)
                  : Math.abs(n) <= 0.5
                  ? (t["spd_" + t.id] = n < 0 ? -0.16 : 0.16)
                  : (t["spd_" + t.id] = n / 2),
                  t["pos_" + t.id] || (t["pos_" + t.id] = 0),
                  _(t),
                  (document.onmousemove = null),
                  (document.onmouseup = null);
              }
            });
        }
        function u(e) {
          e.preventDefault();
          var t = e.target;
          for (t.touchTip = !1; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          clearInterval(t["int_" + t.id]),
            (t["old_" + t.id] = e.targetTouches[0].screenY),
            (t["o_t_" + t.id] = new Date().getTime());
          var a = t.getAttribute("top");
          t["o_d_" + t.id] = a ? parseFloat(a.replace(/em/g, "")) : 0;
        }
        function v(e) {
          e.preventDefault();
          var t = e.target;
          for (t.touchTip = !0; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          (t["new_" + t.id] = e.targetTouches[0].screenY),
            (t["n_t_" + t.id] = new Date().getTime());
          var a = (18 * (t["new_" + t.id] - t["old_" + t.id])) / 370;
          (t["pos_" + t.id] = t["o_d_" + t.id] + a),
            (t.style.transform = "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-webkit-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-moz-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-ms-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-o-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            t.setAttribute("top", t["pos_" + t.id] + "em");
        }
        function y(e) {
          e.preventDefault();
          var t = e.target;
          if (!t.touchTip) return !1;
          for (;;) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          var a =
            (t["new_" + t.id] - t["old_" + t.id]) /
            (t["n_t_" + t.id] - t["o_t_" + t.id]);
          Math.abs(a) <= 0.2
            ? (t["spd_" + t.id] = a < 0 ? -0.08 : 0.08)
            : Math.abs(a) <= 0.5
            ? (t["spd_" + t.id] = a < 0 ? -0.16 : 0.16)
            : (t["spd_" + t.id] = a / 2),
            t["pos_" + t.id] || (t["pos_" + t.id] = 0),
            _(t);
        }
        function _(e) {
          var t = 0,
            a = !1,
            r = b.maxY - b.minY + 1;
          clearInterval(e["int_" + e.id]),
            (e["int_" + e.id] = setInterval(function () {
              var n = e["pos_" + e.id],
                s = e["spd_" + e.id] * Math.exp(-0.03 * t);
              if (((n += s), Math.abs(s) > 0.1));
              else {
                s = 0.1;
                var d = 2 * Math.round(n / 2);
                Math.abs(n - d) < 0.02 ? (a = !0) : n > d ? (n -= s) : (n += s);
              }
              switch (
                (n > 8 && ((n = 8), (a = !0)), e.getAttribute("data-datetype"))
              ) {
                case "date_yy":
                  var o = 8 - 2 * (r - 1);
                  if ((n < o && ((n = o), (a = !0)), a)) {
                    var l = Math.abs(n - 8) / 2;
                    g(e, l), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_mm":
                  var m = b.gearDate.querySelector(".date_yy"),
                    c = parseInt(m.getAttribute("val"));
                  if ((w[c].Intercalation ? w[c].Intercalation : 0) && b.type)
                    var u = 12;
                  else var u = 11;
                  var v = 0;
                  c == r - 1 && (u = (b.type, b.maxM - 1)),
                    0 == c && (v = b.type ? b.minM - 1 : b.minM);
                  var o = 8 - 2 * (u - v);
                  if ((n < o && ((n = o), (a = !0)), a)) {
                    var l = Math.abs(n - 8) / 2 + v;
                    g(e, l), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_dd":
                  var m = b.gearDate.querySelector(".date_yy"),
                    y = b.gearDate.querySelector(".date_mm"),
                    c = parseInt(m.getAttribute("val")),
                    _ = parseInt(y.getAttribute("val")),
                    f = i(c, _),
                    h = f - 1,
                    p = 0;
                  c == r - 1 &&
                    12 == _ + 1 &&
                    (h = b.type ? b.maxD - 25 : b.maxD - 1),
                    0 == c &&
                      2 == _ + 1 &&
                      (p = b.type ? b.minD - 1 : b.minD + 6);
                  var o = 8 - 2 * (h - p);
                  if ((n < o && ((n = o), (a = !0)), a)) {
                    var l = Math.abs(n - 8) / 2 + p;
                    g(e, l), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_hh":
                  var o = -18;
                  if ((n < o && ((n = o), (a = !0)), a)) {
                    var l = Math.abs(n - 8) / 2;
                    g(e, l), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_ii":
                  var o = -110;
                  if ((n < o && ((n = o), (a = !0)), a)) {
                    var l = Math.abs(n - 8) / 2;
                    g(e, l), clearInterval(e["int_" + e.id]);
                  }
              }
              (e["pos_" + e.id] = n),
                (e.style.transform = "translate(0," + n + "em)"),
                (e.style["-webkit-transform"] = "translate(0," + n + "em)"),
                (e.style["-moz-transform"] = "translate(0," + n + "em)"),
                (e.style["-ms-transform"] = "translate(0," + n + "em)"),
                (e.style["-o-transform"] = "translate(0," + n + "em)"),
                e.setAttribute("top", n + "em"),
                t++;
            }, 6));
        }
        function g(e, t) {
          (t = Math.round(t)), e.setAttribute("val", t), n();
        }
        function f(e, t) {
          var a = b.gearDate.querySelector(".J_finish"),
            r = b.gearDate.querySelector(".J_cancel");
          if ((e.preventDefault(), a.getAttribute("data-isconfirm") - 0 && !t))
            return (
              p(),
              a.setAttribute("data-isconfirm", 0),
              (b.gearDate.querySelector(".J_dateChoice").style.display =
                "block"),
              (b.gearDate.querySelector(".J_dateHeader").style.display =
                "block"),
              (b.gearDate.querySelector(".J_confirmContainer").style.display =
                "none"),
              (a.innerHTML = "确定日期"),
              (r.innerHTML = "取消选择"),
              !1
            );
          if ("function" == typeof window.CustomEvent) {
            var n = new CustomEvent("input");
            b.trigger.dispatchEvent(n);
          }
          document.body.removeChild(b.gearDate);
        }
        function h(e) {
          var t = p(),
            a = b.gearDate.querySelector(".J_finish"),
            r = b.gearDate.querySelector(".J_cancel");
          if (
            !(a.getAttribute("data-isconfirm") - 0) &&
            "true" == b.trigger.getAttribute("data-confirm")
          ) {
            var n = b.gearDate.querySelector(".J_confirmNongliText"),
              i = b.gearDate.querySelector(".J_confirmGongliText");
            (b.gearDate.querySelector(".J_dateChoice").style.display = "none"),
              (b.gearDate.querySelector(".J_dateHeader").style.display =
                "none"),
              (b.gearDate.querySelector(".J_confirmContainer").style.display =
                "block"),
              a.setAttribute("data-isconfirm", 1);
            var d = b.gearDate.querySelector(".J_confirmInfo");
            b.type
              ? d.setAttribute(
                  "class",
                  "J_confirmInfo date-confirm__info date-confirm__info--nongli"
                )
              : d.setAttribute(
                  "class",
                  "J_confirmInfo date-confirm__info date-confirm__info--gongli"
                ),
              (a.innerHTML = "确认"),
              (r.innerHTML = "返回修改");
            var o = "",
              l = "";
            void 0 !== t.hh &&
              (t.hh < 0
                ? ((o = "时辰未知"), (l = "时辰未知"))
                : ((l = t.hh + "时"), (o = s("hhcn", t.hh) + "时")));
            var m = "";
            void 0 !== t.ii && t.ii >= 0 && t.hh >= 0 && (m = t.ii + "分"),
              (i.innerHTML = t.yy + "年" + t.mm + "月" + t.dd + "日 " + l + m);
            var c = t._mm < 0 ? s("rm", -t._mm) : s("mm", t._mm);
            return (
              (n.innerHTML =
                "" != m
                  ? t._yy + "年" + c + s("dd", t._dd) + " " + l + m
                  : t._yy + "年" + c + s("dd", t._dd) + " " + o + m),
              !1
            );
          }
          b.trigger.setAttribute("data-date", t.yy + "-" + t.mm + "-" + t.dd),
            b.trigger.setAttribute(
              "data-sdate",
              t._yy + "-" + t._mm + "-" + t._dd
            ),
            b.trigger.setAttribute("data-hour", t.hh),
            b.trigger.setAttribute("data-minute", t.ii);
          var u = b.trigger.getAttribute("data-toid-date"),
            v = b.trigger.getAttribute("data-toid-hour"),
            y = b.trigger.getAttribute("data-toid-minute"),
            _ = b.trigger.getAttribute("data-toid-sdate");
          u &&
            (document.getElementById(u).value = t.yy + "-" + t.mm + "-" + t.dd),
            v && (document.getElementById(v).value = t.hh < 0 ? "" : t.hh),
            _ &&
              (document.getElementById(_).value =
                t._yy + "-" + t._mm + "-" + t._dd),
            y && (document.getElementById(y).value = t.ii < 0 ? "" : t.ii);
          var g = "",
            m = "";
          if ((y && t.hh >= 0 && t.ii >= 0 && (m = t.ii + "分"), b.type)) {
            var c = t._mm < 0 ? s("rm", -t._mm) : s("mm", t._mm);
            v &&
              (g =
                t.hh < 0
                  ? "时辰未知"
                  : "" != m
                  ? t.hh + "时"
                  : s("hhcn", t.hh) + "时"),
              (b.trigger.value =
                "农历:" + t._yy + "年" + c + s("dd", t._dd) + " " + g + m);
          } else
            v && (g = t.hh < 0 ? "时辰未知" : t.hh + "时"),
              (b.trigger.value =
                "公历:" + t.yy + "-" + t.mm + "-" + t.dd + " " + g + m);
          b.callback({
            date: t.yy + "/" + t.mm + "/" + t.dd,
            yy: t.yy,
            mm: t.mm,
            dd: t.dd,
            hh: t.hh,
            _date: t._yy + "/" + Math.abs(t._mm) + "/" + t._dd,
            _dateCn:
              t._yy +
              "/" +
              (t._mm < 0 ? s("rm", -t._mm) : s("mm", t._mm)) +
              "/" +
              s("dd", t._dd),
            _yy: t._yy,
            _mm: Math.abs(t._mm),
            _mmCn: t._mm < 0 ? s("rm", -t._mm) : s("mm", t._mm),
            run: t._mm < 0,
            _dd: t._dd,
            _ddCn: s("dd", t._dd),
            _hh: t.hh,
            _hhCn: s("hhcn", t.hh) || "时辰未知",
          }),
            f(e, "finish");
        }
        function p() {
          var e = b.maxY - b.minY + 1,
            t = parseInt(
              Math.round(
                b.gearDate.querySelector(".date_yy").getAttribute("val")
              )
            ),
            a = (t % e) + b.minY,
            r =
              parseInt(
                Math.round(
                  b.gearDate.querySelector(".date_mm").getAttribute("val")
                )
              ) + 1,
            n =
              parseInt(
                Math.round(
                  b.gearDate.querySelector(".date_dd").getAttribute("val")
                )
              ) + 1,
            i = b.gearDate.querySelector(".date_hh") ? 1 : 0;
          if (i)
            var o =
              parseInt(
                Math.round(
                  b.gearDate.querySelector(".date_hh").getAttribute("val")
                )
              ) - 1;
          var l = b.gearDate.querySelector(".date_ii") ? 1 : 0,
            m = "";
          if (l) {
            var c = parseInt(
              Math.round(
                b.gearDate.querySelector(".date_ii").getAttribute("val")
              )
            );
            o && o >= 0 && c >= 0 && (m = c + "分");
          }
          var u = w[t].Intercalation ? w[t].Intercalation : 0;
          b.type &&
            u &&
            (u == r - 1 ? (r = -(r - 1)) : u < r - 1 ? (r -= 1) : (r = r));
          var v = d(b.type, a, r, n),
            y = b.gearDate.querySelector(".J_dateHeader");
          if (b.type) {
            b.trigger.setAttribute("data-type", 1);
            var _ = r < 0 ? s("rm", -r) : s("mm", r),
              g = "";
            return (
              i &&
                (g =
                  o < 0
                    ? "时辰未知"
                    : "" != m
                    ? o + "时"
                    : s("hhcn", o) + "时"),
              (y.innerHTML =
                "农(阴)历：" + a + "年" + _ + s("dd", n) + " " + g + m),
              {
                yy: v.yy,
                mm: v.mm,
                dd: v.dd,
                _yy: a,
                _mm: r,
                _dd: n,
                hh: o,
                ii: c,
              }
            );
          }
          b.trigger.setAttribute("data-type", 0);
          var g = "";
          return (
            i && (g = o < 0 ? "时辰未知" : o + "时"),
            (y.innerHTML =
              "公(阳)历：" + a + "年" + r + "月" + n + "日 " + g + m),
            {
              _yy: v.yy,
              _mm: v.mm,
              _dd: v.dd,
              yy: a,
              mm: r,
              dd: n,
              hh: o,
              ii: c,
            }
          );
        }
        var b = this,
          w = [
            new l(38, 0, 0, 38, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(26, 6, 2, 44, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new l(45, 0, 3, 49, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(35, 0, 4, 54, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new l(24, 4, 5, 59, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new l(43, 0, 0, 5, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1),
            new l(32, 0, 1, 10, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1),
            new l(21, 2, 2, 15, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new l(40, 0, 3, 20, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new l(28, 7, 5, 26, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(47, 0, 6, 31, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(36, 0, 0, 36, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(26, 5, 1, 41, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new l(44, 0, 3, 47, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1),
            new l(33, 0, 4, 52, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new l(23, 3, 5, 57, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new l(42, 0, 6, 2, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new l(30, 8, 1, 8, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new l(48, 0, 2, 13, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new l(38, 0, 3, 18, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new l(27, 6, 4, 23, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(45, 0, 6, 29, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0),
            new l(35, 0, 0, 34, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new l(24, 4, 1, 39, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new l(43, 0, 2, 44, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new l(32, 0, 4, 50, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new l(20, 3, 5, 55, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new l(39, 0, 6, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new l(29, 7, 0, 5, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new l(47, 0, 2, 11, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new l(36, 0, 3, 16, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0),
            new l(26, 5, 4, 21, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0, 1),
            new l(45, 0, 5, 26, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new l(33, 0, 0, 32, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1),
            new l(22, 4, 1, 37, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new l(41, 0, 2, 42, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1),
            new l(30, 8, 3, 47, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1),
            new l(48, 0, 5, 53, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1),
            new l(37, 0, 6, 58, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new l(27, 6, 0, 3, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0),
            new l(46, 0, 1, 8, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new l(35, 0, 3, 14, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 1),
            new l(24, 4, 4, 19, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1),
            new l(43, 0, 5, 24, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1),
            new l(32, 10, 6, 29, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1),
            new l(50, 0, 1, 35, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new l(39, 0, 2, 40, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1),
            new l(28, 6, 3, 45, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 0),
            new l(47, 0, 4, 50, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new l(36, 0, 6, 56, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new l(26, 5, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1),
            new l(45, 0, 1, 6, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0),
            new l(34, 0, 2, 11, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0),
            new l(22, 3, 4, 17, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new l(40, 0, 5, 22, 1, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new l(30, 8, 6, 27, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1),
            new l(49, 0, 0, 32, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1),
            new l(37, 0, 2, 38, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new l(27, 5, 3, 43, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1),
            new l(46, 0, 4, 48, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1),
            new l(35, 0, 5, 53, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1),
            new l(23, 4, 0, 59, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(42, 0, 1, 4, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(31, 0, 2, 9, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new l(21, 2, 3, 14, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new l(39, 0, 5, 20, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new l(28, 7, 6, 25, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new l(48, 0, 0, 30, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1),
            new l(37, 0, 1, 35, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1),
            new l(25, 5, 3, 41, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new l(44, 0, 4, 46, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new l(33, 0, 5, 51, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(22, 4, 6, 56, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(40, 0, 1, 2, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(30, 9, 2, 7, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new l(49, 0, 3, 12, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1),
            new l(38, 0, 4, 17, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new l(27, 6, 6, 23, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new l(46, 0, 0, 28, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0),
            new l(35, 0, 1, 33, 1, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new l(24, 4, 2, 38, 0, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new l(42, 0, 4, 44, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new l(31, 0, 5, 49, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new l(21, 2, 6, 54, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new l(40, 0, 0, 59, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new l(28, 6, 2, 5, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new l(47, 0, 3, 10, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 1),
            new l(36, 0, 4, 15, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new l(25, 5, 5, 20, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new l(43, 0, 0, 26, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1),
            new l(32, 0, 1, 31, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0),
          ],
          D = !1,
          A = 0;
        "ontouchstart" in window
          ? (b.trigger.addEventListener("touchstart", function (e) {
              A = Date.now();
            }),
            b.trigger.addEventListener("touchmove", function (e) {
              D = !0;
            }),
            b.trigger.addEventListener("touchend", function (e) {
              !D && Date.now() - A < 150 && (e.preventDefault(), t()),
                (D = !1),
                (A = 0);
            }))
          : b.trigger.addEventListener("click", function () {
              t();
            });
      },
    }),
    datePicker
  );
})();
