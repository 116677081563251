import React, { memo, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Banner from "../Banner/Banner.tsx";
import Storage from "../../components/Storage/Storage.tsx";
import axios from "axios";
import isMobile from "is-mobile";
import QRCode from "qrcode.react";
import { CountDown } from "@nutui/nutui-react";
import { useHistory } from "react-router-dom";
import { Toast } from "@nutui/nutui-react";
import {
  getChineseEra,
  getContent,
  getData,
  createOrder,
  wxCreateOrder,
  queryOder,
  jsapiInfo,
} from "../../api/index.ts";
import { isWeiXin,ScrollTop } from "../../utils/index.ts";

import EwmImg from "../../static/images/ewm.png";
import wvImg from "../../static/images/wx.svg";
import zfbImg from "../../static/images/zfb.svg";
import securesdImg from "../../static/images/securesd.png";
import BotImg6 from "../../static/images/secure.png";
import BotBners from "../../static/images/bannerTops.png";
import Icon1 from "../../static/images/icon1.png";
import Icon2 from "../../static/images/icon2.png";
import Icon3 from "../../static/images/icon3.png";
import Icon4 from "../../static/images/icon4.png";
import Icon5 from "../../static/images/icon5.png";
import Icon6 from "../../static/images/icon6.png";
import "./style.css";

const PayPage = memo(() => {
  const history = useHistory();
  const [blocks, setBlocks] = useState("0");
  const [orderNo, setOrderNo] = useState(null);
  const [listImg, setListImg] = useState([
    { name: "终身运势", img: Icon1 },
    { name: "事业财运", img: Icon2 },
    { name: "姻缘详批", img: Icon3 },
    { name: "合婚配对", img: Icon4 },
    { name: "2023运势", img: Icon5 },
    { name: "十年运程", img: Icon6 },
  ]);

  const [width, setWidth] = useState(0);

  const [datas, setDatas]: any = useState(
    Storage.get("uuid") ? Storage.get("mpdata") || {} : []
  );

  const stateRef = useRef({ endTime: Date.now() + 60 * 10 * 1000 });

  const [contentList, setContentList] = useState([]);

  const [progressTitle, setProgressTitle] = useState("生在生成基本命盘");

  const [progressWidth, setProgressWidth] = useState(0);

  const [progressIsEnd, setProgressIsEnd] = useState(false);
  const [payInfo, setPayInfo] = useState({});
  let timerx = null;
  useEffect(() => {
    let timers = [];
    let num = 0;
    let i = 1;
    for (let k = 1; k < 45; k++) {
      let timer = setTimeout(() => {
        let id = "id" + k;
        const dom = document.getElementsByClassName(id)[0];
        let style = dom.style;
        style.opacity = "1";
        style.transition = "1s opacity";
        i = k;
      }, num);
      timers.push(timer);
      num += 500;
    }

    return () => {
      for (let k in timers) {
        clearTimeout(timers[k]);
      }
      clearInterval(timerx);
    };
  }, []);
  // const [xings,setXings] = useState(datas.xings1)

  const getUsers = async () => {
    const data = Storage.get("balance");

    console.log(data);
    //返回命盘数据
    const info = await getData(data);
    console.log(info, "返回命盘数据");
    setDatas(info);
    Storage.set("mpdata", info);
  };

  const getDzhi = async () => {
    const data = Storage.get("balance");
    //获取天干地支内容
    const info = await getChineseEra(data);
    console.log(info, "获取天干地支内容");
  };

  const getPy = async () => {
    const data = Storage.get("balance");
    //获取获取批言
    const info = await getContent(data);
    console.log(info, "获取批言");
    const detail = [
      {
        name: "爱情婚姻",
        con:
          info.loveMarragied && info.loveMarragied.replaceAll("★", "<br/>★ "),
      },
      {
        name: "性格特点",
        con:
          info.personCharacter &&
          info.personCharacter.replaceAll("★", "<br/>★ "),
      },
      {
        name: "职业财运",
        con:
          info.careerFortune && info.careerFortune.replaceAll("★", "<br/>★ "),
      },
      {
        name: "体质健康",
        con:
          info.physicalHealth && info.physicalHealth.replaceAll("★", "<br/>★ "),
      },
      {
        name: "后天补救",
        con:
          info.acquiredRemedy && info.acquiredRemedy.replaceAll("★", "<br/>★ "),
      },
    ];
    setContentList(detail);
  };

  const progressFun = () => {
    const textArr = [
      "正在生成基本命盘",
      "正在输入命主信息",
      "正在安命宫",
      "正在计算宫位星神",
      "正在生成报告",
    ];
    let width = 0;
    let timer = null;
    let num = 0;
    timer = setInterval(() => {
      if (num == textArr.length - 1 && width == 100) {
        setProgressIsEnd(true);
        ScrollTop(300,1)
        return clearInterval(timer);
      }

      if (width == 100) {
        width = 0;
        num += 1;
        setProgressTitle(textArr[num]);
      }
      width += 10;
      setProgressWidth(width);
    }, 500);
  };

  const jsapiPay = (e) => {
    e.preventDefault()
    const params = {
      openid: Storage.get("openId"),
      orderNo,
    };
    jsapiInfo(params).then((data) => {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appid, //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonce_str, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式:
          paySign: data.paySign, //微信签名
          // timeStamp: "1681439731205",
          // nonce_str: "9ad1cb6530c24b1e9faf6320e31380c8",
          // paySign:
          //   "00D45833B12ABFC734918EE393974C03AE39845DDE28720E41112CD16E060511",
          // appid: "wxaac1e07f45d0c1e4",
          // signType: "RSA",
          // prepay_id: "wx141035374447582b5b10882d663eee0000",
        },
        function (res) {
          console.log(res, "支付");
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.success("支付成功");
            setBlocks("2");
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            Toast.warn("支付取消");
          } else if (res.err_msg == "get_brand_wcpay_request:fail") {
            Toast.fail("支付失败");
          } else if (
            res.errMsg ==
            "chooseWXPay:fail, the permission value is offline verifying"
          ) {
            Toast.fail("支付权限地址不正确");
          }
        }
      );
    });
  };
  const queryOrderStatus = (orderNo) => {
    console.log(orderNo, "orderNo");
    queryOder({ orderNo }).then((rej) => {
      if (rej.status > 0) {
         Toast.success("支付成功");
        setBlocks("2");
        ScrollTop(300,3)
        clearInterval(timerx);
      }
    });
  };

  useEffect(() => {
    console.log(document.body.clientWidth, "获取宽度", history);
    setWidth(document.body.clientWidth);
    getUsers();
    getDzhi();
    getPy();
    progressFun();
  }, []);

  const applyPay = () => {
    let data = { ...Storage.get("balance") };

    data.status = 0;
    delete data.inputName;
    createOrder(data).then((res) => {
      console.log(res, "获取数据");
      setOrderNo(res.orderNo);
      if (!isWeiXin()) {
        wxCreateOrder({ orderNo: res.orderNo }).then((rej) => {
          console.log(rej, "rej");

          setPayInfo({
            h5_code_url: rej.h5_code_url,
            h5_url: rej.h5_url,
          });
          if (width > 800) {
            timerx = setInterval(() => {
              queryOrderStatus(res.orderNo);
            }, 1000);
          }
        });
       
      }
      ScrollTop(260,2)
       setBlocks("1");
    });
  };
  return (
    <PayPageBox>
      <BoxTOP />
      <PanBx>
        <table className="tab">
          <tbody>
            <tr>
              <td className="id1">
                <div className="idLeft">
                  <div className="Lt id37">
                    <div className="LtOne">
                      {datas && datas.xings1 ? datas.xings1.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings1Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"#FF5C5F"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id25">{datas ? datas.gong6 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi6 : ""}</div>
                </div>
              </td>
              <td className="id2">
                <div className="idLeft">
                  <div className="Lt id38">
                    <div className="LtOne">
                      {datas ? datas.xings2?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings2Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"#FF5C5F"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id26">{datas ? datas.gong7 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi7 : ""}</div>
                </div>
              </td>
              <td className="id3">
                <div className="idLeft">
                  <div className="Lt id39">
                    <div className="LtOne">
                      {datas ? datas.xings3?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings3Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id27">{datas ? datas.gong8 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi8 : ""}</div>
                </div>
              </td>
              <td className="id4">
                <div className="idLeft">
                  <div className="Lt id40">
                    <div className="LtOne">
                      {datas ? datas?.xings4?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings4Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id28">{datas ? datas.gong9 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi9 : ""}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="id12">
                <div className="idLeft">
                  <div className="Lt id36">
                    <div className="LtOne">
                      {datas ? datas.xings12?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings12Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id24">{datas ? datas.gong5 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi5 : ""}</div>
                </div>
              </td>
              <td className="id13" rowSpan={2} colSpan={2}>
                <div
                  className="id13Box"
                  style={{
                    color: "white",
                    fontSize: isMobile() ? ".35rem" : "18px",
                    textAlign: "left",
                    marginLeft: isMobile() ? "0.2rem" : "20px",
                  }}
                >
                  <div className="xm id14" style={{ opacity: 0 }}>
                    姓名：{datas ? datas.name : ""}
                  </div>
                  <div className="xb id15" style={{ opacity: 0 }}>
                    性别：{datas ? (datas.sex === 1 ? "男" : "女") : ""}
                  </div>
                  <div
                    className="nl id16"
                    style={{ color: "#E5BC65", opacity: 0 }}
                  >
                    {datas ? datas.birthday : ""}
                  </div>
                  <div className="sc id17" style={{ opacity: 0 }}>
                    生辰：{datas ? datas.birth : ""}
                  </div>
                  <div className="mj id18" style={{ opacity: 0 }}>
                    命局：{datas ? datas.life : ""}
                  </div>
                  <div className="mz id19" style={{ opacity: 0 }}>
                    命主：{datas ? datas.ordain : ""}
                  </div>
                  <div className="sz id20" style={{ opacity: 0 }}>
                    身主：{datas ? datas.bodyMaster : ""}
                  </div>
                </div>
              </td>
              <td className="id5">
                <div className="idLeft">
                  <div className="Lt id41">
                    <div className="LtOne">
                      {datas ? datas.xings5?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings5Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id29">{datas ? datas.gong10 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi10 : ""}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="id11">
                <div className="idLeft">
                  <div className="Lt id35">
                    <div className="LtOne">
                      {datas ? datas.xings11?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings11Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id23">{datas ? datas.gong4 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi4 : ""}</div>
                </div>
              </td>
              <td className="id6">
                <div className="idLeft">
                  <div className="Lt id42">
                    <div className="LtOne">
                      {datas ? datas.xings6?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings6Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id30">{datas ? datas.gong11 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi11 : ""}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="id10">
                <div className="idLeft">
                  <div className="Lt id34">
                    <div className="LtOne">
                      {datas ? datas.xings10?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings10Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id22">{datas ? datas.gong3 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi3 : ""}</div>
                </div>
              </td>
              <td className="id9">
                <div className="idLeft">
                  <div className="Lt id33">
                    <div className="LtOne">
                      {datas ? datas.xings9?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings9Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id21">{datas ? datas.gong2 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi2 : ""}</div>
                </div>
              </td>
              <td className="id8">
                <div className="idLeft">
                  <div className="Lt id44">
                    <div className="LtOne">
                      {datas ? datas.xings1?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings1Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id32">{datas ? datas.gong1 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi1 : ""}</div>
                </div>
              </td>
              <td className="id7">
                <div className="idLeft">
                  <div className="Lt id43">
                    <div className="LtOne">
                      {datas ? datas.xings7?.substr(0, 2) : ""}
                      <span style={{ color: "red" }}>
                        {datas ? datas.xings7Child : ""}
                      </span>
                    </div>
                    {/* <div className='LtTwo'>
                                        太阴<span style={{color:"red"}}>科</span>
                                    </div> */}
                  </div>
                  <div className="Lb id31">{datas ? datas.gong12 : ""}</div>
                </div>
                <div className="idRight">
                  <div className="Rb">{datas ? datas.ganzhi12 : ""}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </PanBx>
      <div
        className="progress"
        style={{ display: progressIsEnd === false ? "block" : "none" }}
      >
        <div className="title">{progressTitle}</div>
        <div className="bar">
          <span style={{ width: progressWidth + "%" }}></span>
        </div>
      </div>

      <div
        style={{ display: progressIsEnd && blocks == "0" ? "block" : "none",paddingBottom:'20px' }}
      >
        <FontBox style={{ display: blocks === "0" ? "block" : "none" }}>
          <div className="FontOne">命盘显示</div>
          <div className="FontTwo">
            2023年的感情、事业、财运会出现 <div>重要变动</div>{" "}
          </div>
        </FontBox>
        <FlexsButImg onClick={() => applyPay()}>
          点击领取生辰详解命书
        </FlexsButImg>
      </div>
      {/* 查询结果 */}

      <div
        className="result"
        style={{ display: blocks === "2" ? "block" : "none" }}
      >
        {contentList.map((item, index) => {
          return (
            <div className="result-list" key={index}>
              <h4>{item.name}</h4>
              <p dangerouslySetInnerHTML={{ __html: item.con }}></p>
            </div>
          );
        })}
      </div>

      {/* <Show style={{ display: blocks === '1' ? 'block' : 'none' }} /> */}
      <Bottom style={{ display: blocks >= "1" ? "block" : "none" }}>
        <div className="Top">
          <div
            className="Title"
            style={{ display: blocks === "1" ? "block" : "none" }}
          >
            <div className="Font1">付费查看生辰详解</div>
            <div className="Font2">不准可以马上退款</div>
          </div>
          <div className="Fonts">
            &emsp;&emsp;资深易学先生根据生日量身定制的专属生辰详解命书，{" "}
            <span>一人仅此一份</span>，已有<span>1397904</span>
            人购买，好评率高达<span>98.99%</span>
            ！内容详细准确，包括以下方面：
          </div>
          <div className="List">
            <div>婚姻感情</div>
            <div>财运状况</div>
            <div>事业发展</div>
            <div>健康寿命</div>
            <div>子女旺衰</div>
            <div>中年运势</div>
            <div>老年运势</div>
            <div>一生贵贱</div>
          </div>
          <div style={{ display: blocks === "1" ? "block" : "none" }}>
            <div className="Price">
              <div className="jyj">结缘价</div>
              <div className="kq">¥29</div>
              <div className="yj">原价¥99</div>
            </div>
            <div className="Timeout">
              <div className="yh">
                {" "}
                <span style={{ marginRight: "5px" }}>距优惠结束还剩</span>{" "}
                <CountDown
                  endTime={stateRef.current.endTime}
                  format="mm 分 ss 秒"
                />
              </div>

              {width > 800 ? (
                <div>
                  <div className="wf">打开微信扫二维码支付</div>
                  <div className="ewmImg">
                    {/* <div className='ImgOnes'>
                            <div className='a'></div>
                            <div className='b'></div>
                            </div> */}
                    {/* <QRCode
                                        id="qrCode"
                                        className='ImgTwos'
                                        value={payUrl}
                                        size={240} // 二维码的大小
                                        fgColor="#000000" // 二维码的颜色

                                    // imageSettings={{ // 二维码中间的logo图片
                                    //     src: "https://zengwu.com.cn/images/avatar.png",
                                    //     height: 100,
                                    //     width: 100,
                                    //     excavate: true, // 中间图片所在的位置是否镂空
                                    // }}
                                    /> */}
                    <img className="ImgTwos" src={payInfo.h5_code_url} alt="" />
                  </div>
                </div>
              ) : null}
            </div>

            {width < 800 ? (
              <div>
                {isWeiXin() ? (
                  <a
                    href="/#"
                    className="wx-btn"
                    onClick={(e) => jsapiPay(e)}
                  >
                    <img src={wvImg} alt="" />
                    <span>微信支付</span>
                  </a>
                ) : (
                  <a href={payInfo.h5_url} className="wx-btn">
                    <img src={wvImg} alt="" />
                    <span>微信支付</span>
                  </a>
                )}
              </div>
            ) : null}
          </div>
          {/* <div className='minFont'>PayPal国际支付</div> */}
          <div className="minImg"></div>
          <div className="minFonts">安全联盟已验证请放心支付</div>
          <div className="BannerBox">
            <Banner />
            <div className="BannerLeft">
              <div>
                已有 <span>1524971</span> 人
              </div>
              <div>查看生辰详解</div>
            </div>
          </div>
          {/* <div className='ItemList'>
                        {
                            listImg.map((item, index) => {
                                return <div className='ListBlocks' key={index}>
                                    <img src={item.img} alt="" />
                                    <div>{item.name}</div>
                                </div>

                            })
                        }
                    </div> */}
          <div className="Fontsyw">有疑问点击这里联系我们</div>
          <div className="Bot6"></div>
          <div className="Ul">
            <div>网站备案:粤ICP备2021055203号</div>
            <div>公司名称：广州天机文化科技有限公司</div>
            <div>公司地址:广州市白云区石井聚源街50号4#栋3A层580</div>
          </div>
        </div>
      </Bottom>
    </PayPageBox>
  );
});

const Show = styled.div`
  height: 300px;
`;

const Bottom = styled.div`
  width: 100%;
  .Top {
    width: 100%;
    margin-top: 30px;
    .Title {
      height: 39.59px;
      width: 70%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      color: white;
      font-size: 19px;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
  }
  .Fonts {
    width: 99%;
    margin: 0 auto;
    font-size: 19px;
    margin-top: 20px;
    color: white;
    text-align: left;
    margin-top: 5px;
    span {
      color: #ff5c5f;
    }
    @media (max-width: 1200px) {
      font-size: 0.35rem;
    }
  }
  .List {
    width: 95%;
    height: 84px;
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 33px;
      border: 1px solid #ff5c5f;
      color: white;
      font-size: 17px;
      @media (max-width: 1200px) {
        width: 1.65rem;
      }
    }
  }
  .ItemList {
    width: 95%;
    height: 224px;
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    .ListBlocks {
      width: 150px;
      height: 102px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div {
        width: 74px;
        height: 24px;
        color: white;
        font-size: 17px;
        font-weight: 600;
      }
      img {
        width: 74px;
        height: 75px;
      }
      @media (max-width: 1200px) {
        width: 26%;
      }
    }
    // @media (max-width:1200px) {
    //     width:7rem;
    //  }
  }
  .Price {
    display: flex;
    width: 100%;
    height: 36px;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    .jyj {
      width: 72px;
      height: 33px;
      background-color: #ff5c5f;
      margin-right: 0.5em;
      border-radius: 0.5em;
      color: white;
      line-height: 33px;
      font-size: 18px;
      margin-top: 3px;
    }
    .kq {
      width: 47px;
      height: 36px;
      font-size: 29px;
      color: #ff5c5f;
    }
    .yj {
      width: 60px;
      height: 21px;
      color: #dddddd;
      text-decoration: line-through;
      font-size: 15px;
      margin-top: 6px;
      margin-left: 6px;
    }
  }
  .wx-btn {
    border-color: #21bd0a;
    background-color: #21bd0a;
    animation: animationButton 2s linear infinite alternate;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    width: 80%;
    margin: 20px auto;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    @keyframes animationButton {
      0% {
        transform: scale(0.95);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(0.95);
      }
    }
  }
  .Timeout {
    width: 252px;
    margin: 0 auto;
    margin-top: 20px;
    .yh {
      color: white;
      font-size: 17px;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .wf {
      color: white;
      font-size: 17px;
    }

    .ewmImg {
      flex-direction: column;
      display: flex;
      .ImgOnes {
        width: 252px;
        display: flex;
        .a {
          width: 126px;
          height: 32px;
          background: url(${wvImg});
          background-size: contain;
          max-width: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #21bd0a;
        }
        .b {
          width: 126px;
          height: 32px;
          background: url(${zfbImg});
          background-size: contain;
          max-width: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #00a8f2;
        }
      }
      .ImgTwos {
        width: 252px;
        height: 252px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .minFont {
    width: 100%;
    color: white;
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
  }
  .minImg {
    width: 380px;
    height: 46px;
    background: url(${securesdImg});
    background-size: cover;
    max-width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 20px auto;
    @media (max-width: 1200px) {
      width: 7rem;
      background-size: contain;
    }
  }
  .minFonts {
    width: 100%;
    color: #21bd0a;
    text-align: center;
    font-size: 18px;
  }
  .BannerBox {
    width: 440px;
    margin: 0 auto;
    justify-content: space-around;
    height: 64.78px;
    display: flex;
    align-items: center;
    .BannerLeft {
      width: 136px;
      color: white;
      font-size: 15px;
      text-align: left;
    }
    @media (max-width: 1200px) {
      width: 7rem;
    }
  }
  .Fontsyw {
    cursor: pointer;
    margin: 0 auto;
    margin-top: 64px;
    margin-bottom: 32px;
    color: white;
    font-size: 21.6px;
    font-weight: 600;
  }
  .Ul {
    width: 352px;
    height: 57px;
    margin: 0 auto;
    margin-top: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    div {
      color: white;
      font-size: 15px;
      text-align: left;
    }
  }
  .Bot6 {
    width: 374px;
    height: 152px;
    margin: 0 auto;
    background: url(${BotImg6});
    background-size: 100% 100%;
    background-position: center center;
  }
`;

const FlexsButImg = styled.div`
  width: 70%;
  height: 39px;
  margin: 20px auto;
  animation: identifierS 3.5s linear infinite;
  color: #2b3481;
  cursor: pointer;
  background-color: #f8d48b;
  border-color: #f8d48b;
  border-radius: 8px;
  letter-spacing: 0.1em;
  line-height: 39px;
  font-size: 18px;
  @keyframes identifierS {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
  }
  /* @media (max-width:1200px) {
     width: 100%;
     height: 10rem;
     font-size: .5rem;
     color: red;
  } */
`;

const FontBox = styled.div`
  width: 100%;
  font-size: 18px;
  color: white;
  margin-top: 20px;
  .FontOne {
  }
  .FontTwo {
    display: flex;
    justify-content: center;
    div {
      color: #feed7f;
    }
  }
`;

const PayPageBox = styled.div`
  width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #152d52;
  // height:100vh;
  @media (max-width: 1200px) {
    width: 7.68rem;
    margin: 0 auto;
    background-color: #152d52;
  }
`;
const BoxTOP = styled.div`
  width: 100%;
  height: 153px;
  background: url(${BotBners});
  background-size: cover;
  // background-position: center center;
  @media (max-width: 1200px) {
    height: 3.53rem;
  }
`;
const PanBx = styled.div`
  width: 100%;
  height: 439px;
  .tab {
    width: 100%;
    height: 439px;
    // background:#ccc;
    tr {
      width: 100%;
      // @keyframes goCircelBlock{
      //     0%{
      //         opacity: 0;
      //     }
      //     100%{
      //         opacity: 1;
      //     }
      // }
      td {
        // animation: goCircelBlock 3.5s linear 1;
        width: 112px;
        height: 102px;
        border: 1px solid #f8d48b;
        border-radius: 0.3em;
        background-color: #213a63;
        opacity: 0;
        font-size: 18px;
        .idLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          float: left;
          .Lt {
            opacity: 0;
            display: flex;
            height: 69px;
            width: 40px;
            .LtOne {
              width: 20px;
              color: #acb6dd;
            }
            .LtTwo {
              color: #acb6dd;
            }
          }
          .Lb {
            margin-top: 9px;
            color: #191944;
            background-color: #f7e7ad;
            border-radius: 0 0.25em;
            opacity: 0;
            @media (max-width: 1200px) {
              margin-top: 0.3rem;
              font-size: 0.3rem;
            }
          }
        }
        .idRight {
          height: 100%;
          float: right;
          display: flex;
          align-items: flex-end;
          .Rb {
            width: 20px;
            color: #feed7f;
          }
        }
        @media (max-width: 1200px) {
          font-size: 0.3rem;
        }
      }
      // .id1{
      //     display:flex;
      //     justify-content: space-between;
      //     font-size: 18px;
      //     .id1Left{
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;
      //         .Lt{
      //             display: flex;
      //             height:69px;
      //             width:40px;
      //             .LtOne{
      //                 color: #ACB6DD;
      //             }
      //             .LtTwo{
      //                 color: #ACB6DD;
      //             }
      //         }
      //         .Lb{
      //             color: #191944;
      //             background-color: #F7E7AD;
      //             border-radius: 0 0.25em;
      //         }
      //     }
      //     .id1Right{
      //         display: flex;
      //         align-items: flex-end;
      //         .Rb{
      //             width:20px;
      //             color: #FEED7F;
      //         }
      //     }
      // }
    }
  }

  @media (max-width: 1200px) {
    width: 7.5rem;
    height: 8.8rem;
  }
`;

export default PayPage;
