import React, { memo,useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import BotImg6 from '../../static/images/secure.png'
const Query = memo(() => {
  const [changeVsdh,setChangeVsdh] = useState('')
  const [changeVsxm,setChangeVsxm] = useState('')
  const [changeVsdhh,setChangeVsdhh] = useState('')
  const [changeVsdtkyy,setChangeVsdtkyy] = useState('')
  const history = useHistory()

  const ChangeInputValuedh = (e:any)=>{

    const value: any = e.currentTarget.value
    const reg = /^-?\d*(\.\d*)?$/
    if (
        (!isNaN(value) && reg.test(value)) ||
        value === '' ||
        value === '-'    
    ){
        if(value.length>9){
            alert('不能大于9')
            // setChangeVsdh('')
        }else{
            setChangeVsdh(e.target.value)
        }
    }
    
  }
  const ChangeInputValuexm = (e:any)=>{
    setChangeVsxm(e.target.value)
  }
  const ChangeInputValuedhh = (e:any)=>{

    const value: any = e.currentTarget.value
    const reg = /^-?\d*(\.\d*)?$/
    if (
        (!isNaN(value) && reg.test(value)) ||
        value === '' ||
        value === '-'    
    ){
        
        setChangeVsdhh(e.target.value)
        
    }
  }
  const ChangeInputValuetkyy = (e:any)=>{
    setChangeVsdtkyy(e.target.value)
  }
  const getFrom = ()=>{
    // Storage.set('balance',{})
    console.log(changeVsdh)
    console.log(changeVsxm)
    console.log(changeVsdhh)
    console.log(changeVsdtkyy)
    // history.push("CustomerService")
    // window.location.reload()

  }
  return (
    <QueryBox>
        <Boxs>
            <BoxsTop>
                <TopTile>申请退款</TopTile>
                <TopFonts>
                    <div className='fontsOne'>每人只退款一次</div>
                    <div className='fontsTwo'>仅支持七天内退款</div>
                    <div className='fontsThree'>恶意退款会使运势更差</div>
                </TopFonts>
            </BoxsTop>
            <Froms>
                <div className='TdOne'>
                    <div className='TdOneName'>单号：(总共9位的数字)</div>
                    <input className='Inputs' type="text" value={changeVsdh}  onChange={ChangeInputValuedh} />
                </div>
                <div className='LineFont'>微信支付凭证点进去，支付宝在账单里</div>
                <div className='TdTwo'>
                    <div className='TdTwoName'>姓名：</div>
                    <input className='Inputs' type="text"  onChange={ChangeInputValuexm} />
                </div>
                <div className='TdTwo'>
                    <div className='TdTwoName'>电话：</div>
                    <input className='Inputs' type="text" value={changeVsdhh}  onChange={ChangeInputValuedhh} />
                </div>
                <div className='TdThree'>
                    <div className='TdTdThreeName' placeholder='' >退款原因：</div>
                    <textarea className='InputsCheck'  onChange={ChangeInputValuetkyy} />
                </div>
                <div className='OnSubmits' onClick={getFrom}>提交申请</div>
            </Froms>
            <Bottoms>
                <div className='Fontsyw'>有疑问点击这里联系我们</div>
                <div className='Bot6'></div>
                <div className='Ul' >
                    <div>网站备案:&emsp;粤ICP备2021055203号</div>
                    <div>公司名称:&emsp;广州天机文化科技有限公司</div>
                    <div>公司地址:&emsp;广州市白云区石井聚源街50号4#栋3A层580</div>
                </div>
            </Bottoms>
        </Boxs>
    </QueryBox>
  )
})

const Bottoms = styled.div`
    width:640px;
    height:283px;
    // background:#CCC;

    .Fontsyw{
        cursor: pointer;
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 32px;
        color:#622D02;
        font-size: 21.6px;
        font-weight: 600;
        &:hover{
            /* color:; */
        }
    }
    .Ul{
        width:352px;
        height:57px;
        margin:0 auto;
        margin-top:10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        div{
          color:#622D02;
          font-size: 13px;
          text-align:left;
        }
    }
    .Bot6{
        width: 374px;
        height: 152px;
        margin: 0 auto;
        background: url(${BotImg6});
        background-size: 100% 100%;
        background-position: center center;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    @media (max-width:1200px) {
        width: 7rem;
        margin: 0 auto;
    }


`

const Froms = styled.div`
    height:455px;
    width:100%;
    background:#FFFFFF;
    .TdOne{
        width:500px;
        // margin-top:10px;
        height: 75px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .TdOneName{
            text-align: left;
            font-size:20px;
            color:#622D02;
        }
        .Inputs{
            text-align: center;
            width:500px;
            height:42px;
            border-radius:5px;
            outline: none;
            border: 0;
            border: 1px solid #FA982E;
            color:#622D02;
            font-weight:600;
            font-size:18px;
            box-sizing: border-box;
            @media (max-width:1200px) {
                width: 100%;
            }
        }
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .LineFont{
        width:500px;
        margin:0 auto;
        text-align: left;
        font-size:20px;
        color:#622D02;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .TdTwo{
        width:500px;
        margin:0 auto;
        margin-top:10px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .TdTwoName{
            text-align: left;
            font-size:20px;
            color:#622D02;
        }
        .Inputs{
            text-align: center;
            width:500px;
            height:42px;
            border-radius:5px;
            outline: none;
            border: 0;
            border: 1px solid #FA982E;
            color:#622D02;
            font-weight:600;
            font-size:18px;
            box-sizing: border-box;
            @media (max-width:1200px) {
                width: 100%;
            }
        }
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .TdThree{
        width:500px;
        height:100px;
        margin:0 auto;
        margin-top:10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .TdTdThreeName{
            text-align: left;
            font-size:20px;
            color:#622D02;
        }
        .InputsCheck{
            text-align: left;
            width:500px;
            height:80px;
            resize:none;
            border-radius:5px;
            outline: none;
            border: 0;
            border: 1px solid #FA982E;
            color:#622D02;
            font-size:18px;
            box-sizing: border-box;
            @media (max-width:1200px) {
                width: 100%;
            }
        }
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .OnSubmits{
        width:500px;
        margin:15px auto;
        border-radius:5px;
        height:42px;
        line-height:42px;
        background: #FA892E;
        color: #FFFFFF;
        text-align: center;
        font-size: 18px;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    @media (max-width:1200px) {
        width: 7.68rem;
    }
`

const TopTile = styled.div`
    height:60px;
    width:100%;
    line-height: 60px;
    padding: 15px 0;
    text-align: center;
    color: #622D02;
    font-weight:600;
    font-size:22px;
`
const TopFonts = styled.div`
    height:70px;
    width:100%;
    .fontsOne{
        text-align: center;
        color: #622D02;
        font-weight:600;
        font-size:18px;
    }
    .fontsTwo{
        text-align: center;
        color: #622D02;
        font-weight:600;
        font-size:18px;
    }
    .fontsThree{
        text-align: center;
        color: #622D02;
        font-weight:600;
        font-size:18px;
    }
`

const BoxsTop = styled.div`
    height:180px;
    width:100%;
    background:#FFFFFF;
`

const Boxs = styled.div`
    width: 640px;
    margin:0 auto;
    min-height: 100vh;
    @media (max-width:1200px) {
        width: 7.68rem;
    }
`

const QueryBox = styled.div`
    width: 100%;
    background-color: #EEEEEE;
    min-height: 100vh;
    @media (max-width:1200px) {
        width: 7.68rem;
        margin:0 auto;
    }
`

export default Query