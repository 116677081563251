import React, { memo } from "react";

// const Storage = memo(() => {
//   return (
//     <div>Storage</div>
//   )
// })

// export default Storage

var Storage = {
  set(key: any, value) {
    if (value instanceof Object) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  get(key: any) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      return localStorage.getItem(key);
    }
  },
  remove(key: any) {
    localStorage.removeItem(key);
  },
};
export default Storage;
