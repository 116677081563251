import { Toast } from "@nutui/nutui-react";
import axios, { AxiosRequestConfig } from "axios";
import Storage from "../components/Storage/Storage.tsx";
const baseURL = process.env.NODE_ENV === "development" ? "/api" : "https://mp.wenzhuang.vip";
const timeout = 15000;

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = timeout;

// 添加请求拦截器
axios.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // 在发送请求之前做些什么
    const headers = config.headers || {};

    if (typeof Storage.get("token") != "undefined")
      headers.token = Storage.get("token");

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (res) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const {
      status,
      data,
    } = res;
    if (status != 200) {
      Toast.fail("系统网络错误 ");
    } else if (data) {
    const  { code, data, msg } = res.data
      if (code == 500) {
        Toast.fail(msg);
        return Promise.reject(res.data);
      } else {
        return data;
      } 
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default axios;
