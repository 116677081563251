import React, { memo } from 'react'
import styled from 'styled-components'
import './Bnner.css'
import BotImg6 from '../../static/images/enjefo.jpg'

// import ImgTop from '../../static/images/top12.png'
const Banner = memo(() => {
  return (
    <BannerBox>
        <div className="g-container">
          <ul>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>1分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>2分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>3分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>4分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>5分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>6分钟前看了生辰详解</div>
                </div>
            </li>
            <li>
                <img className='imgss' src={BotImg6} alt="" />
                <div className='namebox'>
                    <div>浙江省宁波市高女士</div>
                    <div>7分钟前看了生辰详解</div>
                </div>
            </li>
          </ul>
        </div>
    </BannerBox>
  )
})

const BannerBox = styled.div`
    width:194px;
    height:48px;
    // background:red;
`


export default Banner