// 数字翻译
export function statusText(list, val, backLabel = "label", value = "value") {
  let text = "";
  try {
    text = list.find((item) => item[value] == val)[backLabel];
  } catch (error) {}
  return text;
}
export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true; // 是微信端
  } else {
      return false;
  }
}
export const ScrollTop = (number = 0, time) => {
  if (!time) {
      document.body.scrollTop = document.documentElement.scrollTop = number;
      return number;
  }
  const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
  let spacingInex = time / spacingTime; // 计算循环的次数
  let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
  let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
  let scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
          spacingInex--;
          ScrollTop(nowTop += everTop);
      } else {
          clearInterval(scrollTimer); // 清除计时器
      }
  }, spacingTime);
};