import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, withRouter, Link } from 'react-router-dom';
import axios from 'axios'
import Ajax from '../../utils/ajax';
import Storage from '../../components/Storage/Storage.tsx';
import { getToken } from '../../api/index.ts';

import BigImg from '../../static/images/banner.png'
import BotImgOne from '../../static/images/banner_bottom1.png'
import BotImgTwo from '../../static/images/banner_bottom2.png'
import ButImg from '../../static/images/button.png'
import Pek from '../../static/images/pek.png'
import BotImg1 from '../../static/images/detail1.png'
import BotImg2 from '../../static/images/detail2.png'
import BotImg3 from '../../static/images/detail3.png'
import BotImg4 from '../../static/images/detail4.png'
import BotImg5 from '../../static/images/detail5.png'
import BotImg6 from '../../static/images/secure.png'
import Banner from '../Banner/Banner.tsx';

import WepperBox from '../ScryWepper/WepperBox.tsx';
import { type } from '@testing-library/user-event/dist/type';
import { useRequest } from 'ahooks';
import {isWeiXin} from '../../utils/index.ts'
import {getOpenId} from '../../api/index.ts'


const Home = memo(() => {
  const [num, setNum]: any = useState()

  const history = useHistory()
  const [animaStyle2, setAnimaStyle2] = useState<React.CSSProperties>({})

  const { data: tokenData, loading, } = useRequest(getToken,{
    onSuccess: (res) => {
      console.log("success",res)
      Storage.set('token',res)
    }
  })



  const toLinks = () => {
    history.push("/State")
    window.location.reload()
  }
  const toLinksQuery = () => {
    history.push("/CustomerService")
    // window.location.reload()
  }
  const handleScroll = () => {
    setNum(document.documentElement.scrollTop)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    console.log(isWeiXin(),'是否是微信环境')
    if(isWeiXin()) {
    
       const query = history.location.search; // '?s=1&f=7'
      const arr = query.split("&")[0]; // ['?s=', 'f=7']
       
      if (arr.indexOf("?openId=") > -1) {
        console.log(arr.split("=")[1]);
         Storage.set("openId", arr.split("=")[1]);
      } else {
         window.location.href = "https://mp.wenzhuang.vip/mingpan/authWX";
       }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setNum])




  return (
    <Box>
      <BoxTop>
        <BoxTopBot>
          {/* <BotImg/> */}
          {/* <BotImgs/> */}
        </BoxTopBot>
        <WepperBox />
      </BoxTop>
      <BannerBox>
        <Banner />
        <BannerLeft>
          <div>已有 <span >1524971</span> 人</div>
          <div>查看生辰详解</div>
        </BannerLeft>
      </BannerBox>
      <Flexs href='/State' onScroll={handleScroll}>
        <FlexsButImg />
      </Flexs>
      <Bot1 />
      <Bot2 />
      <Bot3 />
      <Bot4 />
      <Bot5 />
      <Fonts href='/CustomerService' >有疑问点击这里联系我们</Fonts>
      <Bot6 />
      {/* {
        num>913? */}
      <Fixd href='/State' style={{ animation: num > 913 ? "goCircelBlock 1s linear" : "goCircelNone 1s linear", display: num > 913 ? "flex" : "none" }} >
        <FixdImg />
      </Fixd>
      {/* } */}
      {/* opacity:num>913?"1":"0",transform:"opacity 2s", */}
      <Ul>
        <div>网站备案:粤ICP备2021055203号</div>
        <div>公司名称：广州天机文化科技有限公司</div>
        <div>公司地址:广州市白云区石井聚源街50号4#栋3A层580</div>
      </Ul>
    </Box>
  )
})

const BannerLeft = styled.div`
  width:136px;
  color:white;
  font-size: 15px;
  text-align:left;
`

const Ul = styled.div`
  width:352px;
  height:57px;
  margin:0 auto;
  margin-top:10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  div{
    color:white;
    font-size: 15px;
    text-align:left;
  }
  @media (max-width:1200px) {
    width: 6.5rem;
    margin: 0 auto;
    margin-top: 0.2rem;
  }

`

const Box = styled.div`
  width: 480px;
  margin:0 auto;
  background-color: #152D52;

  @media (max-width:1200px) {
     width: 7.68rem;
     margin:0 auto ;
     /* height: 10rem; */
     /* font-size: .5rem; */
     background-color: #152D52;
  }
`
const BoxTop = styled.div`
  width: 100%;
  height: 758.39px;
  background: url(${BigImg});
  background-size: cover;
  max-width: 100%;
  background-position: center center;
  position: relative;
  overflow:hidden;
  @media (max-width:1200px) {
    width: 7.68rem;
    height: 12.5rem;
    background: url(${BigImg});
    background-size: cover;
    max-width: 100%;
    background-position: center center;
    position: relative;
    overflow:hidden;
  }
`
const BoxTopBot = styled.div`
  width: 100%;
  height: 165.38px;
  position: absolute;
  bottom: 10px;
  @media (max-width:1200px) {
     width: 7.68rem;
     height: 2.65rem;
     position: absolute;
     bottom: .1rem;
  }
`
const BotImg = styled.div`
    width: 388.8px;
    height: 137.84px;
    margin: 0 auto;
    margin-bottom: 9px;
    background: url(${BotImgOne});
    background-size: 100% 100%;
    background-position: center center;
  @media (max-width:1200px) {
    width: 6rem;
    height: 2rem;
    margin-left: 0.75rem;
    margin-bottom: 0.09rem;
    background: url(${BotImgOne});
    background-size: 100% 100%;
    background-position: center center;
  }
`
const BotImgs = styled.div`
    width: 431.97px;
    height: 18.53px;
    margin: 0 auto;
    background: url(${BotImgTwo});
    background-size: 100% 100%;
    background-position: center center;
  @media (max-width:480px) {
    width: 6.5rem;
    height: 0.28rem;
    /* margin: 0 auto; */
    margin-left: 0.5rem;
    background: url(${BotImgTwo});
    background-size: 100% 100%;
    background-position: center center;
  }
`
const BannerBox = styled.div`
    width: 440px;
    margin:0 auto;
    justify-content: space-around;
    height: 64.78px;
    display: flex;
    align-items: center;
  @media (max-width:1200px) {
    width: 6.2rem;
    height: 0.9rem;
  }
`
const Flexs = styled.a`
    width: 100%;
    height: 104.23px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    background: url(${Pek});
    background-size: 100% 100%;
    background-position: center center;
    cursor: pointer;
    @media (max-width:1200px) {
      width: 7.4rem;
      height: 2rem;
      display: flex;
      align-items: center;
      background: url(${Pek});
      background-size: 100% 100%;
      margin-left: 0.05rem;
      margin-right: 0rem;
      background-position: center center;
    }
`

const FlexsButImg = styled.div`
    width: 80%;
    height: 58px;
    margin: 0 auto;
    background: url(${ButImg});
    background-size: 100% 100%;
    background-position: center center;
    animation: identifierS 3.5s linear infinite;

    @keyframes identifierS {
      0%{
        transform: scale(1);
      }
      25%{
        transform: scale(1.1);
      }
      50%{
        transform: scale(1);
      }
      75%{
        transform: scale(1.1);
      }
    }
    @media (max-width:1200px) {
      width: 80%;
      height: 0.9rem;
      margin: 0 auto;
      background: url(${ButImg});
      background-size: 100% 100%;
      background-position: center center;
      animation: identifierS 3.5s linear infinite;
    }
`

const Bot1 = styled.div`
    width: 460.81px;
    height: 882.1px;
    margin:0 auto ;
    margin-top:10px;
    background: url(${BotImg1});
    background-size: 100% 100%;
    background-position: center center;
    @media (max-width:1200px) {
      width: 7rem;
      height: 14.82rem;
      margin-left: 0.25rem;
      margin-top:.1rem;
      background: url(${BotImg1});
      background-size: 100% 100%;
      background-position: center center;
    }
`

const Bot2 = styled.div`
    width: 460.81px;
    height: 753.33px;
    margin:0 auto ;
    margin-top:10px;
    background: url(${BotImg2});
    background-size: 100% 100%;
    background-position: center center;
    @media (max-width:1200px) {
      width: 7rem;
      height: 11.82rem;
      margin-left: 0.25rem;
      margin-top:.1rem;
      background: url(${BotImg2});
      background-size: 100% 100%;
      background-position: center center;
    }
`
const Bot3 = styled.div`
    width: 460.81px;
    height: 1048.02px;
    margin:0 auto ;
    margin-top:10px;
    background: url(${BotImg3});
    background-size: 100% 100%;
    background-position: center center;
    @media (max-width:1200px) {
      width: 7rem;
      height: 16.82rem;
      margin-left: 0.25rem;
      margin-top:.1rem;
      background: url(${BotImg3});
      background-size: 100% 100%;
      background-position: center center;
    }
`
const Bot4 = styled.div`
    width: 460.81px;
    height: 865.73px;
    margin:0 auto ;
    margin-top:10px;
    background: url(${BotImg4});
    background-size: 100% 100%;
    background-position: center center;
    @media (max-width:1200px) {
      width: 7rem;
      height: 14.82rem;
      margin-left: 0.25rem;
      margin-top:.1rem;
      background: url(${BotImg4});
      background-size: 100% 100%;
      background-position: center center;
    }
`
const Bot5 = styled.div`
    width: 460.81px;
    height: 531.25px;
    margin:0 auto ;
    margin-top:10px;
    background: url(${BotImg5});
    background-size: 100% 100%;
    background-position: center center;
    @media (max-width:1200px) {
      width: 7rem;
      height: 8rem;
      margin-left: 0.25rem;
      margin-top:.1rem;
      background: url(${BotImg5});
      background-size: 100% 100%;
      background-position: center center;
    }
`
const Fonts = styled.a`
    cursor: pointer;
    margin: 0 auto;
    margin-top: 64px;
    margin-bottom: 32px;
    color:white;
    font-size: 21.6px;
    font-weight: 600;
    text-decoration:none;
    display: block;
    @media (max-width:1200px) {
      width: 100%;
      font-size: .3rem;
      margin-top: .64rem;
      margin-bottom: .32rem;
    }
`
const Bot6 = styled.div`
    width: 374px;
    height: 152px;
    margin: 0 auto;
    background: url(${BotImg6});
    background-size: 100% 100%;
    background-position: center center;
  @media (max-width:1200px) {
    width: 7rem;
    height: 2.7rem;
    
  }
`

const Fixd = styled.a`

  @keyframes goCircelBlock{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
  }
  @keyframes goCircelNone{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
  }
  cursor: pointer;
  width:480px;
  height:190px;
  position: fixed;
  bottom:32px;
  background: url(${Pek});
  background-size: 100% 100%;
  background-position: center center;
  @media (max-width:1200px) {
    width: 7.4rem;
    height: 2rem;
    display: flex;
    align-items: center;
    background: url(${Pek});
    background-size: 100% 100%;
    margin-left: 0.05rem;
    margin-right: 0rem;
    background-position: center center;
  }
`
const FixdImg = styled.div`
  width: 80%;
  height: 58px;
  margin: 0 auto;
  margin-top:66px;
  background: url(${ButImg});
  background-size: 100% 100%;
  background-position: center center;
  animation: identifierS 3.5s linear infinite;
  @media (max-width:1200px) {
    width: 80%;
    height: 0.9rem;
    margin: 0 auto;
    margin-top:0rem;
    background: url(${ButImg});
    background-size: 100% 100%;
    background-position: center center;
    animation: identifierS 3.5s linear infinite;
  }

  @keyframes identifierS {
    0%{
      transform: scale(1);
    }
    25%{
      transform: scale(1.1);
    }
    50%{
      transform: scale(1);
    }
    75%{
      transform: scale(1.1);
    }
  }

`

export default Home