import React, { memo, useEffect, useState } from "react";
import { Toast } from "@nutui/nutui-react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Storage from "../../components/Storage/Storage.tsx";
import { v4 as uuidv4 } from "uuid";
import { statusText } from "../../utils/index.ts";
import TitleImg from "../../static/images/title.png";
import topImg from "../../static/images/top.png";
import centerImg from "../../static/images/logs.png";
import buttomImg from "../../static/images/bottom.png";
import DlImg from "../../static/images/dl.svg";
import ButImg from "../../static/images/button.png";
import Pek from "../../static/images/pek.png";
import { loadDate } from "./date.ts";
import "../../static/js/datepicker/rui-datepicker.min.css";
import "./style.css";

// 农历月
const lunarMonth = [
  { name: "一", nums: 1 },
  { name: "二", nums: 2 },
  { name: "三", nums: 3 },
  { name: "四", nums: 4 },
  { name: "五", nums: 5 },
  { name: "六", nums: 6 },
  { name: "七", nums: 7 },
  { name: "八", nums: 8 },
  { name: "九", nums: 9 },
  { name: "十", nums: 10 },
  { name: "十一", nums: 11 },
  { name: "十二", nums: 12 },
];
// 公历月
const publicMonth = [
  { name: "1", nums: 1 },
  { name: "2", nums: 2 },
  { name: "3", nums: 3 },
  { name: "4", nums: 4 },
  { name: "5", nums: 5 },
  { name: "6", nums: 6 },
  { name: "7", nums: 7 },
  { name: "8", nums: 8 },
  { name: "9", nums: 9 },
  { name: "10", nums: 10 },
  { name: "11", nums: 11 },
  { name: "12", nums: 12 },
];
const State = memo(() => {
  const history = useHistory();
  const [month, setMonth] = useState(publicMonth);
  const [day, setDay] = useState([]);
  const [pubicDayArr, setPublicDayArr] = useState([]); // 公历日
  const [lunarDayArr, setLunarDayArr] = useState([]); // 农历日

  const [sex, setSex] = useState(1);
  const [date, setDate] = useState(1);
  const [discriminate, setDiscriminate] = useState(0);
  const [changeValue, setChangeValue] = useState("");
  const [pushYear, setPushYear] = useState(2012);

  const [yearArr, setYearArr] = useState([]);
  const [pushMonth, setPushMonth] = useState([]);
  const [pushDay, setPushDay] = useState([]);
  const [pushData, setPushData] = useState({ nums: 14, name: "时辰未知" });

  const createYear = () => {
    const d = new Date();
    const m = d.getMonth() + 1;
    const y = d.getFullYear();
    let curYear = y;

    let yearList = [curYear];
    for (let k = 0; k < 70; k++) {
      curYear--;
      yearList.push(curYear);
    }
    const { publicData, lunar } = getMonthDays(y, m);
    setDay(publicData);
    setPushYear(y);
    setMonths({ nums: m, name: String(m) });

    return yearList;
  };
  //传入年份和月份 获取该年对应月份的天数
  const getMonthDays = (year, month) => {
    const lunarData = [
      { name: "初一", nums: 1 },
      { name: "初二", nums: 2 },
      { name: "初三", nums: 3 },
      { name: "初四", nums: 4 },
      { name: "初五", nums: 5 },
      { name: "初六", nums: 6 },
      { name: "初七", nums: 7 },
      { name: "初八", nums: 8 },
      { name: "初九", nums: 9 },
      { name: "十", nums: 10 },
      { name: "十一", nums: 11 },
      { name: "十二", nums: 12 },
      { name: "十三", nums: 13 },
      { name: "十四", nums: 14 },
      { name: "十五", nums: 15 },
      { name: "十六", nums: 16 },
      { name: "十七", nums: 17 },
      { name: "十八", nums: 18 },
      { name: "十九", nums: 19 },
      { name: "二十", nums: 20 },
      { name: "二十一", nums: 21 },
      { name: "二十二", nums: 22 },
      { name: "二十三", nums: 23 },
      { name: "二十四", nums: 24 },
      { name: "二十五", nums: 25 },
      { name: "二十六", nums: 26 },
      { name: "二十七", nums: 27 },
      { name: "二十八", nums: 28 },
      { name: "二十九", nums: 29 },
      { name: "三十", nums: 30 },
      { name: "三十一", nums: 31 },
    ];

    const day = new Date(year, month, 0).getDate(); //当天数为0 js自动处理为上一月的最后一天
    let dayList = []; // 公历日
    let lunarList = []; // 农历日
    for (let k = 1; k <= day; k++) {
      dayList.push({ nums: k, name: k });
      lunarList.push({ nums: k, name: lunarData[k - 1].name });
    }
    setPublicDayArr(dayList);
    setLunarDayArr(lunarList);
    return {
      lunar: lunarData,
      publicData: dayList,
    };
  };

  const [data, setData] = useState([
    { id: 0, name: "0:00-0:59早子", nums: 0 },
    { id: 1, name: "01:00-02:59丑", nums: 2 },
    { id: 2, name: "03:00-04:59寅", nums: 4 },
    { id: 3, name: "05:00-06:59卯", nums: 6 },
    { id: 4, name: "07:00-08:59辰", nums: 8 },
    { id: 5, name: "09:00-10:59巳", nums: 10 },
    { id: 6, name: "11:00-12:59午", nums: 12 },
    { id: -1, name: "时辰未知", nums: 14 },
    { id: 7, name: "13:00-14:59未", nums: 16 },
    { id: 8, name: "15:00-16:59申", nums: 18 },
    { id: 9, name: "17:00-18:59酉", nums: 20 },
    { id: 10, name: "19:00-20:59戌", nums: 22 },
    { id: 11, name: "21:00-22:59亥", nums: 24 },
    { id: 12, name: "23:00-23:59子", nums: 26 },
  ]);

  const [uuids, setUuids] = useState(Storage.get("uuid"));
  const [months, setMonths] = useState({ nums: 1, name: "1" });
  const [days, setDays] = useState({ nums: 1, name: "1" });
  const [datas, setDatas] = useState();

  const OnSetSex = (id: any) => {
    id === 1 ? setSex(1) : setSex(0);
  };
  // 切换农历 公历
  const OnSetDate = (type: any) => {
    console.log("id", type);
    type === 1 ? setDate(1) : setDate(2);

    type == 1 ? setMonth(publicMonth) : setMonth(lunarMonth);

    type == 1 ? setDay(pubicDayArr) : setDay(lunarDayArr);

    const monthList = type == 1 ? publicMonth : lunarMonth;
    const dayList = type == 1 ? pubicDayArr : lunarDayArr;

    let daysObj = { ...days };
    daysObj.name = statusText(dayList, daysObj.nums, "name", "nums");
    setDays(daysObj);

    let monthObj = { ...months };
    monthObj.name = statusText(monthList, monthObj.nums, "name", "nums");
    setMonths(monthObj);
  };
  const CheckYearMonthDay = (id: any) => {
    setDiscriminate(id);
  };
  // 选择历法生日月份和日要变成中文 和对应的下拉
  // 选择月份 日需要默认一号
  const cancel = (type: number, num: any, name: any) => {
    console.log(type, name);
    const dayObj = { nums: num, name: name };
    console.log(dayObj, "dayObj");
    if (type === 1) {
      setPushYear(name);
    } else if (type === 2) {
      // 设置月份
      setPushMonth(name);
      setMonths(dayObj);
      const { publicData, lunar } = getMonthDays(pushYear, num);
      console.log(name);
      if (date == 1) {
        setDay(publicData);
      } else {
        setDay(lunar);
      }
      setTimeout(() => {
        setDays({ nums: day[0].nums, name: day[0].name });
      }, 200);
    } else if (type === 3) {
      // 设置日期
      setPushDay(name);
      setDays(dayObj);
    } else if (type === 4) {
      // 设置时间范围
      setPushData(dayObj);
      setDatas(name);
    }
    setDiscriminate(0);
  };
  const ChangeInputValue = (e: any) => {
    setChangeValue(e.target.value);
  };

  useEffect(() => {
    loadDate()
    const datas = Storage.get("balance");
    const dom = document.getElementById("datepicker-bazijingpi-150630");
    if (datas) {
      // 回显
      if(Storage.get("isReload")) {
        window.location.reload()
        Storage.set("isReload",false)
      }
     
      console.log(datas, "555");
      setChangeValue(datas.name);
      let years = `${datas.year}-${datas.month}-${datas.day}`;

      dom.setAttribute("data-date", years);
      dom.setAttribute("data-sdate", years);
      dom.setAttribute("value", datas.inputName || '');
      dom.setAttribute("data-type", datas.li == 0 ? "1" : "0");

      dom.setAttribute("data-hour", statusText(data, datas.hour, "id", "nums"));
      console.log(dom);
    } else {
      Storage.set("uuid", uuidv4());
      dom.setAttribute("value", '');
    }
  }, []);

  const OnSubmitData = () => {
    const dom = document.getElementById("datepicker-bazijingpi-150630");
    const dateType = dom.getAttribute("data-type");
    const hour = dom.getAttribute("data-hour");
    let dateObj = {
      0: dom.getAttribute("data-date"), //公历
      1: dom.getAttribute("data-sdate"), // 农历
    };
 
    console.log(hour, date);
    console.log(statusText(data, hour, "nums", "id"));
    const value = dom.value



    if (changeValue == "") {
      console.log(Toast);
      Toast.fail("请输入姓名");

      return;
    } else if (value == "") {
      Toast.fail("请选择生日");
    } else {
      let date = dateObj[dateType].split("-");
      const params = {
        day: Number(date[2]),
        hour: statusText(data, hour, "nums", "id"),
        li: dateType == '1' ? 0 : 1,
        month: Number(date[1]),
        name: changeValue,
        sex: sex,
        uuid: Storage.get("uuid"),
        year: Number(date[0]),
        inputName: value,
      };
      console.log(params);
      Storage.set("balance", params);
      Storage.set("isReload",true)
      history.push("PayPage");
    }
  };

  return (
    <StateBox >
      <SBoxTop>
        <img className="imgOne" src={TitleImg} alt="" />
        <img className="imgTwo" src={topImg} alt="" />
      </SBoxTop>
      <SBoxCenter>
        <CenterItem>
          <Mc>姓名：</Mc>
          <Inputs
            placeholder="点击输入姓名"
            value={changeValue}
            onChange={ChangeInputValue}
          />
        </CenterItem>
        <CenterItem>
          <Mc>性别：</Mc>
          <Radios>
            <div className="radioOne">
              <div className="Yq" onClick={() => OnSetSex(1)}>
                <img
                  src={DlImg}
                  style={{ display: sex === 1 ? "block" : "none" }}
                  alt=""
                />
              </div>
              <div className="Name">男</div>
            </div>
            <div className="radioTwo">
              <div className="Yq" onClick={() => OnSetSex(0)}>
                <img
                  src={DlImg}
                  style={{ display: sex === 0 ? "block" : "none" }}
                  alt=""
                />
              </div>
              <div className="Name">女</div>
            </div>
          </Radios>
        </CenterItem>
        {/* <CenterItem>
          <Mc>历法：</Mc>
          <Radios>
            <div className="radioOne">
              <div className="Yq" onClick={() => OnSetDate(1)}>
                <img
                  src={DlImg}
                  style={{ display: date === 1 ? "block" : "none" }}
                  alt=""
                />
              </div>
              <div className="Name">公历阴历</div>
            </div>
            <div className="radioTwo" style={{ marginLeft: "10px" }}>
              <div className="Yq" onClick={() => OnSetDate(2)}>
                <img
                  src={DlImg}
                  style={{ display: date === 2 ? "block" : "none" }}
                  alt=""
                />
              </div>
              <div className="Name">农历阴历</div>
            </div>
          </Radios>
        </CenterItem> */}
        <CenterItem>
          <Mc>生日：</Mc>
          <From>
            {/*  <div onClick={() => CheckYearMonthDay(1)}>{pushYear + "年"}</div>
            <div onClick={(e) => CheckYearMonthDay(2)}>{months.name}月</div>
           <div onClick={() => CheckYearMonthDay(3)}>
              {days.name}
              {date == 1 ? "日" : ""}{" "}
            </div>
            <div onClick={() => CheckYearMonthDay(4)}>{pushData.name}</div> */}
            <div className="birthday">
              <input
                type="text"
                id="datepicker-bazijingpi-150630"
                data-toid-date="datepicker-bazijingpi-toid-date-150630"
                data-toid-hour="datepicker-bazijingpi-toid-hour-150630"
                name="datetext"
                data-type="1"
                placeholder="请选择出生日期（必填）"
                readOnly
              />
              <input
                type="hidden"
                name="birthday"
                id="datepicker-bazijingpi-toid-date-150630"
              />
              <input
                type="hidden"
                name="hour"
                id="datepicker-bazijingpi-toid-hour-150630"
              />
            </div>
          </From>
          <Qhdemo style={{ display: discriminate === 0 ? "none" : "flex" }}>
            <div
              className="Year"
              style={{ visibility: discriminate === 1 ? "inherit" : "hidden" }}
            >
              {yearArr.map((item, index) => {
                return (
                  <div key={index} onClick={() => cancel(1, 0, item)}>
                    {item + "年"}
                  </div>
                );
              })}
            </div>
            <div
              className="Month"
              style={{ visibility: discriminate === 2 ? "inherit" : "hidden" }}
            >
              {month.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => cancel(2, item.nums, item.name)}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
            <div
              className="Day"
              style={{ visibility: discriminate === 3 ? "inherit" : "hidden" }}
            >
              {day.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => cancel(3, item.nums, item.name)}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
            <div
              className="Data"
              style={{ visibility: discriminate === 4 ? "inherit" : "hidden" }}
            >
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => cancel(4, item.nums, item.name)}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </Qhdemo>
        </CenterItem>
        <Flexs onClick={OnSubmitData}>
          <FlexsButImg />
        </Flexs>
      </SBoxCenter>
      <SBoxBot></SBoxBot>
    </StateBox>
  );
});

const Flexs = styled.a`
  width: 95%;
  margin-left: 2.5%;
  height: 104.23px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  background: url(${Pek});
  background-size: 100% 100%;
  background-position: center center;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 6.7rem;
    height: 1.4rem;
    display: flex;
    align-items: center;
    background: url(${Pek});
    background-size: 100% 100%;
    // margin-left: 0.05rem;
    margin-right: 0rem;
    background-position: center center;
  }
`;

const FlexsButImg = styled.div`
  width: 80%;
  height: 54px;
  margin: 0 auto;
  background: url(${ButImg});
  background-size: 100% 100%;
  background-position: center center;
  animation: identifierS 3.5s linear infinite;

  @keyframes identifierS {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
  }
  @media (max-width: 1200px) {
    width: 70%;
    height: 0.7rem;
    margin: 0 auto;
    background: url(${ButImg});
    background-size: 100% 100%;
    background-position: center center;
    animation: identifierS 3.5s linear infinite;
  }
`;

const Qhdemo = styled.div`
  width: 330px;
  height: 500px;
  // background:red;
  position: absolute;
  top: 53px;
  left: 69px;
  z-index: 9;
  display: flex;
  .Year {
    width: 77px;
    height: 500px;
    background: blue;
    overflow-y: scroll;
    background: white;
    border-radius: 5px;
    div {
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
    @media (max-width: 1200px) {
      height: 5rem;
    }
  }
  .Month {
    width: 58px;
    height: 289px;
    background: white;
    border-radius: 5px;
    div {
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
    @media (max-width: 1200px) {
      height: 5rem;
    }
  }
  .Day {
    width: 71px;
    height: 500px;
    background: white;
    overflow-y: scroll;
    border-radius: 5px;
    div {
      font-size: 18px;
      cursor: pointer;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
    @media (max-width: 1200px) {
      height: 5rem;
    }
  }
  .Data {
    width: 123px;
    height: 318px;
    background: white;
    overflow-y: scroll;
    border-radius: 5px;
    div {
      font-size: 17px;
      cursor: pointer;
      @media (max-width: 1200px) {
        font-size: 0.27rem;
      }
    }
    @media (max-width: 1200px) {
      height: 5rem;
    }
  }
  @media (max-width: 1200px) {
    width: 4.7rem;
    height: 5rem;
  }
`;

const From = styled.div`
  width: 330px;
  height: 42px;
  display: flex;
  div {
    height: 100%;
    color: white;
    font-size: 19px;
    font-weight: 600;
    border: 1px solid #3169bf;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0;
    @media (max-width: 1200px) {
      font-size: 0.27rem;
    }
  }
  // div:nth-child(1) {
  //   width: 71px;
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;
  // }
  // div:nth-child(2) {
  //   width: 65px;
  // }
  // div:nth-child(3) {
  //   width: 65px;
  // }
  // div:nth-child(4) {
  //   width: 128px;
  //   border-right: 1px solid #3169bf;
  //   border-top-right-radius: 5px;
  //   border-bottom-right-radius: 5px;
  //   @media (max-width: 1200px) {
  //     font-size: 0.25rem;
  //   }
  // }
  @media (max-width: 1200px) {
    width: 4.7rem;
    height: 0.8rem;
  }
`;

const Radios = styled.div`
  width: 262px;
  height: 24px;
  display: flex;
  .radioOne {
    display: flex;
    align-items: center;

    .Yq {
      width: 24px;
      height: 24px;
      background-color: #3169bf;
      border-radius: 50%;
      img {
        width: 23px;
        height: 23px;
      }
    }
    .Name {
      color: white;
      font-size: 19px;
      font-weight: 600;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
  }
  .radioTwo {
    display: flex;
    align-items: center;
    margin-left: 50px;
    .Yq {
      width: 24px;
      height: 24px;
      background-color: #3169bf;
      border-radius: 50%;
      img {
        width: 23px;
        height: 23px;
      }
    }
    .Name {
      color: white;
      font-size: 19px;
      font-weight: 600;
      @media (max-width: 1200px) {
        font-size: 0.3rem;
      }
    }
  }
`;

const Inputs = styled.input`
  width: 180px;
  height: 24px;
  outline: none;
  border: 0;
  background: #152a4c;
  color: white;
  font-size: 18px;
  font-weight: 600;
  ::-webkit-input-placeholder {
    color: white;
    font-weight: 100;
  }
  @media (max-width: 1200px) {
    font-size: 0.3rem;
  }
`;

const StateBox = styled.div`
  width: 480px;
  margin: 0 auto;
  background-color: #152d52;
  min-height: 100vh;
  position: relative;
  @media (max-width: 1200px) {
    width: 7.68rem;
    margin: 0 auto;
  }
`;
const SBoxTop = styled.div`
  position: relative;
  overflow: visible;
  .imgOne {
    height: 54.78px;
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    left: 15%;
    top: 40px;
    width: 70%;
    z-index: 3;
    @media (max-width: 1200px) {
      width: 5.68rem;
      margin: 0 auto;
      height: 0.94rem;
      top: 0.6rem;
      left: 12.5%;
    }
  }
  .imgTwo {
    height: 39px;
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    left: 2.5%;
    top: 67px;
    width: 95%;
    z-index: 2;
    @media (max-width: 1200px) {
      height: 0.7rem;
      width: 7rem;
      left: 4%;
      top: 1.1rem;
    }
  }
`;
const SBoxBot = styled.div`
  height: 39px;
  background: url(${buttomImg});
  background-size: 100% 100%;
  background-position: center center;
  width: 95%;
  position: absolute;
  top: 526px;
  margin-left: 2.5%;
  @media (max-width: 1200px) {
    height: 0.7rem;
    width: 7rem;
    left: 4%;
    top: 9.29rem;
    margin: 0 auto;
  }
`;
const SBoxCenter = styled.div`
  position: absolute;
  top: 106px;
  left: 2.5%;
  width: 95%;
  height: 420px;
  background: url(${centerImg});
  background-size: 100% auto;
  text-align: center;

  @media (max-width: 1200px) {
    width: 7rem;
    left: 4%;
    top: 1.8rem;
    height: 7.5rem;
  }
`;
const CenterItem = styled.div`
  display: flex;
  align-items: center;
  background: #152a4c;
  border-radius: 8px;
  margin: 0 4% 16px;
  padding: 8px 0px 8px 16px;
  position: relative;
  @media (max-width: 1200px) {
    // width: 7rem;
    // left: 4%;
    // top: 1.8rem;
    // height: 7.5rem;
  }
`;

const Mc = styled.div`
  height: 46px;
  width: 54px;
  line-height: 46px;
  color: white;
  font-size: 18px;
  @media (max-width: 1200px) {
    font-size: 0.3rem;
  }
`;

export default State;
