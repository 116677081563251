import api from '../utils/ajax.ts'


export function getToken (){
    return api.get('/mingpan/getAccessToken?keyId=pan7452692')
}
//获取天干地支内容
export function getChineseEra (data){
    return api.post('/mingpan/getChineseEra',data)
}
//获取获取批言
export function getContent (data){
    return api.post('/mingpan/getContent',data)
}

//返回命盘数据
export function getData (data){
    return api.post('/mingpan/getData',data)
}

//创建订单
export function createOrder (data){
    return api.post('/mingpan/createOrder',data)
}

//微信H5下单
export function wxCreateOrder (params){
    return api.get('/mingpan/wxOreateOrder',{params})
}

//微信H5下单
export function queryOder (params){
    return api.get('/mingpan/queryByOrderNo',{params})
}

//微信获取opnenid
export function getOpenId (){
    return api.get('/mingpan/authWX')
}

//微信获取JSAPI签名数据
export function jsapiInfo(params) {
    return api.get('/mingpan/JsAPI', { params })
}