import './App.css';
import State from './view/State/State.tsx'
import Home from './view/Home/Home.tsx'
import PayPage from './view/PayPage/PayPage.tsx'
import Query from './view/Query/Query.tsx'
import CustomerService from './view/CustomerService/CustomerService.tsx'
import { Route,BrowserRouter,Switch,Router } from 'react-router-dom';
import '@nutui/nutui-react/dist/style.css'
import './static/js/jquery-1.7.2.min.js'
import './static/js/datepicker/rui-datepicker.min.js'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
            {/* <Route component={Home} path="/" exact ></Route> */}
            <Route path="/" exact >
              <Home/>
            </Route>
            <Route path="/State" exact >
              <State/>
            </Route>
            <Route path="/PayPage"exact  >
              <PayPage/>
            </Route>
            <Route path="/Query" exact >
              <Query/>
            </Route>
            <Route path="/CustomerService" exact >
              <CustomerService/>
            </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
