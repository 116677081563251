import React, { memo } from 'react'
import styled from 'styled-components'

import ImgTop from '../../static/images/top12.png'
import ImgTwos from '../../static/images/top11.png'
import ImgThree from '../../static/images/top10.png'
import ImgFour from '../../static/images/top9.png'
const WepperBox = memo(() => {
  return (
    <Boxc>
        <div className='one'></div>
        <div className='two'></div>
        <div className='three'></div>
        <div className='four'></div>
    </Boxc>
  )
})

const Boxc = styled.div`
    width:480px;
    height:480px;
    position:relative;
    top:60px;
    @keyframes unCircel{
        0%{
            transform:rotate(360deg)
        }
        100%{
            transform:rotate(0deg)
        }
    }
    .one{
      position:absolute;
      left: 234px;
      width:480px;
      height:480px;
      background:url(${ImgTop});
      background-size: 100%;
      border-radius:50%;
      text-align:center;
      animation: unCircel 7s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center; 
      @media (max-width:1200px) {
        width: 7.8rem;
        height: 7.8rem;
        position: absolute;
        left: 3.5rem;
      }
      
    }
    @keyframes goCircel{
      0%{
          transform:rotate(0deg)
      }
      100%{
          transform:rotate(360deg)
      }
    }
    .two{
      position:absolute;
      left: 288px;
      top:60px;
      width:360px;
      height:360px;
      background:url(${ImgTwos});
      background-size: 100%;
      border-radius:50%;
      text-align:center;
      animation: goCircel 7s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center; 
      @media (max-width:1200px) {
        width: 6rem;
        height: 6rem;
        left: 4.4rem;
        top: 0.9rem;
      }
    }
    .three{
      position:absolute;
      left: 344px;
      top: 115px;
      width:250px;
      height:250px;
      background:url(${ImgThree});
      background-size: 100%;
      border-radius:50%;
      text-align:center;
      animation: unCircel 7s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center; 
      @media (max-width:1200px) {
        width: 4.1rem;
        height: 4.1rem;
        left: 5.3rem;
        top: 1.85rem;
      }
    }
    .four{
      position:absolute;
      left: 405px;
      top: 165px;
      width:150px;
      height:150px;
      background:url(${ImgFour});
      background-size: 100%;
      border-radius:50%;
      text-align:center;
      animation: goCircel 7s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center; 
      @media (max-width:1200px) {
        width: 2.5rem;
        height: 2.5rem;
        left: 6.3rem;
        top: 2.65rem;
      }
    }
  @media (max-width:1200px) {
    width: 6.78rem;
    height: 8rem;
    position: relative;
    top: 1rem;
  }
`

export default WepperBox