
    export const loadDate = () => {
    
    try {
        var loadScript = function (config) {
            console.log('调用我', config)
            if (config.isunload) {
                var jsNode = document.getElementById(config.id);
                if (!jsNode) {
                    jsNode = document.createElement("script");
                    jsNode.setAttribute("type", "text/javascript");
                    jsNode.setAttribute("id", config.id);
                    jsNode.setAttribute("src", config.src);
                    document.getElementsByTagName("head")[0].appendChild(jsNode)
                }
                // config.callback()
                 console.log(jsNode,'jsnode')
                jsNode.addEventListener("load", function () {
                    console.log('点我')
                    config.callback()
                })
         
            } else {
                config.callback()
            }
        };
        loadScript({
            id: "app-script-ruidatepicker",
            isunload: true,
            src: "../../static/js/datepicker/rui-datepicker.min.js",
            callback: function () {
                console.log('调用我',window)
                new RuiDatepicker()
                    .init("#datepicker-bazijingpi-150630")
            }
        });
      
     
    } catch (error) {
        console.warn(error)
        }
    }
