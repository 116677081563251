import React, { memo,useEffect,useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import BotImg6 from '../../static/images/secure.png'
import Jt from '../../static/images/jt.svg'
import ewmSimg from '../../static/images/ewmd.jpg'
const CustomerService = memo(() => {
    const [changeVsdh,setChangeVsdh] = useState('')
    const history = useHistory()

    const ChangeInputValuedh = (e:any)=>{

        const value: any = e.currentTarget.value
        const reg = /^-?\d*(\.\d*)?$/
        if (
            (!isNaN(value) && reg.test(value)) ||
            value === '' ||
            value === '-'    
        ){
            if(value.length>9){
                alert('不能大于9')
                // setChangeVsdh('')
            }else{
                setChangeVsdh(e.target.value)
            }
        }

    }
    const getFrom = ()=>{
        console.log(changeVsdh)
    }
    const toQuery = ()=>{
        console.log(changeVsdh)
        history.push("Query")
        // window.location.reload()
    }
    useEffect(()=>{
        document.getElementsByClassName("Lisf")[2].style.borderBottom="0px"
    },[])

  return (
    <CustomerServiceBox>
        <Boxs>
            <BoxsTop>
                <div className='submitFont'>
                    输入单号查看结果
                </div>
                <div className='oddnumbers'>
                    单号是9位数字,在支付凭证里
                </div>
                <input type="text" className='bodinput' value={changeVsdh}  onChange={ChangeInputValuedh} />
               
                <div className='OnSubmits' onClick={getFrom}>点击查看结果</div>
                <div className='ListUls'>
                    <div className='Lis'>
                        <a className='LisFont' href='Query'>申请退款</a> 
                        <img src={Jt} alt="" />
                    </div>
                    <div className='Lis'>
                        <div className='LisFont'>取消退款申请</div> 
                        <img src={Jt} alt="" />
                    </div>
                    <div className='Lis'>
                        <div className='LisFont'>付款后没看到结果</div> 
                        <img src={Jt} alt="" />
                    </div>
                    <div className='Lisf'>
                        <div className='LisFont'>姓名输错了怎么办</div> 
                        <div className='LisFont2'>姓名错了没有关系，测算是根据出生日期进行的，只要出生日期相同测算结果一样。</div> 
                    </div>
                    <div className='Lisf'>
                        <div className='LisFont'>出生日期输错了怎么办</div> 
                        <div className='LisFont2'>出生日期是没办法更改的，您可以点击上面申请退款，提交退款申请后再重新付款测算。</div> 
                    </div>
                    <div className='Lisf'>
                        <div className='LisFont'>对结果不满意怎么办</div> 
                        <div className='LisFont2'>我们讲究随缘，不论您出于什么原因对结果不满意，点击上面申请退款即可，财务会无条件的给您退款。您也没必要生气，一是我们多您那点钱发不了财，二是您气坏了身子代价更大。</div> 
                    </div>
                    <div className='Lis'>
                        <div className='LisFont'>申请退款 点击这里</div> 
                        <img src={Jt} alt="" />
                    </div>
                    <div className='Lis'>
                        <div className='LisFont'>找易学专家 点击这里</div> 
                        <img src={Jt} alt="" />
                    </div>
                </div>
                <div className='Botrwm'>
                    <div className='rwmFontwx'>
                        加微信联系客服(限已付款客户)
                    </div>
                    <div className='rwmFonthf'>
                        加微信直接说明想咨询的问题<br/>
                        发“在吗、有没有人”不会回复
                    </div>
                    <div className='rwmnum'>
                        2256635139
                    </div>
                    <img src={ewmSimg} alt="" className='rwmimg' />
                </div>
            </BoxsTop>
            <Bottoms>
                <div className='Fontsyw'>有疑问点击这里联系我们</div>
                <div className='Bot6'></div>
                <div className='Ul' >
                    <div>网站备案:&emsp;粤ICP备2021055203号</div>
                    <div>公司名称:&emsp;广州天机文化科技有限公司</div>
                    <div>公司地址:&emsp;广州市白云区石井聚源街50号4#栋3A层580</div>
                </div>
            </Bottoms>
        </Boxs>
    </CustomerServiceBox>
  )
})

const BoxsTop = styled.div`
    height:1458px;
    width:100%;
    background:white;
    padding-top: 20px;
    .submitFont{
        text-align: center;
        color: #622D02;
        font-weight: 600;
        font-size: 22px;
    }
    .oddnumbers{
        margin:10px auto;
        text-align: center;
        color: #622D02;
        font-size: 18px;
    }
    .bodinput{
        margin:10px auto;
        text-align: center;
        width:500px;
        height:42px;
        border-radius:5px;
        outline: none;
        border: 0;
        border: 1px solid #FA982E;
        color:#622D02;
        font-weight:600;
        font-size:18px;
        box-sizing: border-box;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .OnSubmits{
        width:500px;
        margin:15px auto;
        border-radius:5px;
        height:42px;
        line-height:42px;
        background: #FA892E;
        color: #FFFFFF;
        text-align: center;
        font-size:18px;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .ListUls{
        width:500px;
        margin:0 auto;
        .Lisf{
            width:460px;
            /* height:100px; */
            height:132px;
            border-top: 1px solid #DDDDDD;
            border-bottom: 1px solid #DDDDDD;
            display: flex;
            padding: 0 20px;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            .LisFont{
                color: #2E39FA;
                // font-weight: 600;
                font-size: 18px;
               
                &:hover{
                    color:red;
                }
                @media (max-width:1200px) {
                    margin-left: 0.3rem;
                }
            }
            .LisFont2{
                color:#622D02;
                font-size: 18px;
                text-align:left;
                @media (max-width:1200px) {
                    padding-left: 0.3rem;
                    font-size: 0.3rem;
                }
            }
            @media (max-width:1200px) {
                width: 100%;
                padding:0;
            }
        }
        .Lis{
            width:460px;
            height:47px;
            border-top: 1px solid #DDDDDD;
            border-bottom: 1px solid #DDDDDD;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .LisFont{
                color: #2E39FA;
                font-weight: 600;
                font-size: 18px;
                text-decoration:none;
                display: block;
                 &:hover{
                    color:red;
                }
                @media (max-width:1200px) {
                    margin-left: 0.3rem;
                }
            }
            img{
                width: 18px;
                height: 18px;
            }
            @media (max-width:1200px) {
                width: 100%;
                padding:0;
            }
        }
        .Lis:nth-child(2){
            border-top: 0;
            border-bottom: 0;
        }
        .Lis:nth-child(7){
            border-bottom: 0;
        }
        .Lis:nth-child(8){
            /* border-top: 0; */
        }
        .Lisf{
            border-top: 0;
        }
        .Lisf:nth-child(3){
            border-bottom: 0;
        }
        @media (max-width:1200px) {
            width: 7rem;
            margin: 0 auto;
        }
    }
    .Botrwm{
        width:500px;
        height:495px;
        background: #f9f9f9;
        margin:0 auto;
        border-radius:10px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .rwmFontwx{
            color: #622D02;
            font-weight: 600;
            font-size: 18px;
        }
        .rwmFonthf{
            color: #999999;
            font-size: 18px;
        }
        .rwmnum{
            color: #622D02;
            font-weight: 600;
            font-size: 18px;
        }
        .rwmimg{
            width:261px;
            height:261px;
        }
        @media (max-width:1200px) {
            width: 7rem;
            margin: 0 auto;
        }
    }
    @media (max-width:1200px) {
        width: 7.68rem;
    }
`

const Bottoms = styled.div`
    width:640px;
    height:315px;
    // background:#CCC;

    .Fontsyw{
        cursor: pointer;
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 32px;
        color:#622D02;
        font-size: 21.6px;
        font-weight: 600;
        &:hover{
            color:;
        }
    }
    .Ul{
        width: 447px;
        height: 91px;
        /* width:352px;
        height:57px; */
        margin:0 auto;
        margin-top:10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        div{
          color:#622D02;
          font-size: 18px;
          text-align:left;
        }
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    .Bot6{
        width: 374px;
        height: 152px;
        margin: 0 auto;
        background: url(${BotImg6});
        background-size: 100% 100%;
        background-position: center center;
        @media (max-width:1200px) {
            width: 7rem;
        }
    }
    @media (max-width:1200px) {
        width: 7rem;
        margin: 0 auto;
    }
`

const CustomerServiceBox = styled.div`
    width: 100%;
    background-color: #EEEEEE;
    min-height: 100vh;
    @media (max-width:1200px) {
        width: 7.68rem;
        margin:0 auto;
    }
`
const Boxs = styled.div`
    width: 640px;
    margin:0 auto;
    min-height: 100vh;
    @media (max-width:1200px) {
        width: 7.68rem;
    }

`


export default CustomerService